import React, { useState } from "react";
import { Button, Space, Typography, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import _ from 'lodash';
import { downloadAllQuestionsAsCSV, downloadAllOptionsAsCSV, downloadQuestionsCSV, downloadOptionsCSV, downloadInstructionsTxt, downloadAllCSVsAsZip, processDataSet, processStataInstructions } from './dataHelpers'

const { Title, Paragraph, Text } = Typography;

function FolderSelector() {
  const originalHeader =
    "StartDate,EndDate,Status,IPAddress,Progress,Duration (in seconds),Finished,RecordedDate,ResponseId,RecipientLastName,RecipientFirstName,RecipientEmail,ExternalReference,LocationLatitude,LocationLongitude,DistributionChannel,UserLanguage";
  const [datasets, setDatasets] = useState([]);
  const [loadingSelection, setLoadingSelection] = useState(false);
  const selectFolder = async () => {
    try {
      const folderHandle = await window.showDirectoryPicker();
      loadDatasetsFromFolder(folderHandle);
    } catch (error) {
      console.error("Error selecting folder:", error);
    }
  };

  const loadDatasetsFromFolder = async (folderHandle) => {
    setLoadingSelection(true);
    const datasetsWithJsonData = [];
    for await (const entry of folderHandle.values()) {
      
      if (entry.kind === "directory") {
        if((entry.name).charAt(0) !== '.'){
          console.log(entry.name);

          const datasetName = entry.name;
          let dataSetId = '';
          let dataSetInformation = { allQuestions: [], allOptions: [] };
          let stataInstructions = '';
          let completed = true;
  
          try {
            let jsonFileHandle = null;
            let qsfFileHandle = null;
            /** Get and process Files Data */
            try {
              const dataBackupHandle = await entry.getDirectoryHandle("dataBackup");
              /* Definitions JSON*/
              jsonFileHandle = await dataBackupHandle.getFileHandle(
                `${datasetName}_json.json`
              );
            } catch (error) {
              completed = false;
              console.error(`Error getting json file in '${datasetName}':`, error);
  
            }
  
            const jsonInfo = await jsonFileHandle.getFile();
            const jsonString = await new Response(jsonInfo).text();
            const jsonData = JSON.parse(jsonString);
  
            try {
              /* Qualtrics definition */
              const definitionsBackupHandle = await entry.getDirectoryHandle("definitionsBackup");
              qsfFileHandle = await definitionsBackupHandle.getFileHandle(
                `${datasetName}.qsf`
              );
            } catch (error) {
              completed = false;
              console.error(`Error getting QSF file in '${datasetName}':`, error);
  
            }
  
            const qsfInfo = await qsfFileHandle.getFile();
            const qsfString = await new Response(qsfInfo).text();
            const qsfData = JSON.parse(qsfString);
            dataSetId = qsfData['SurveyEntry']['SurveyID'];
            dataSetInformation = processDataSet(jsonData, qsfData);
            stataInstructions = processStataInstructions(dataSetInformation);
          } catch (error) {
            completed = false;
            console.error(`Error processing dataset '${datasetName}':`, error);
          }
          datasetsWithJsonData.push({
            name: datasetName,
            surveyId: dataSetId,
            completed: completed,
            dataSetInformation: dataSetInformation,
            dataSetInstructions: stataInstructions
          });
        
        }
       
      }
    }
    setLoadingSelection(false);
    setDatasets(datasetsWithJsonData);
  };

  const columns = [
    {
      title: 'Data Set Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <h3>{text}</h3>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      render: (text, record) => (
        text ? (<CheckCircleTwoTone twoToneColor="#52c41a" />) : (<CloseCircleTwoTone />)
      ),
      sorter: (a, b) => a.completed - b.completed,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Questions Total',
      dataIndex: 'questionsTotal',
      key: 'questionsTotal',
      sorter: (a, b) => a.questionsTotal - b.questionsTotal,
    },
    {
      title: 'Questions Generated by JS and in Export Map',
      dataIndex: 'questionsJSInExportMap',
      key: 'questionsJSInExportMap',
      sorter: (a, b) => a.questionsJSInExportMap - b.questionsJSInExportMap,
    },
    {
      title: 'Questions Generated by JS and not in Export Map',
      dataIndex: 'questionsJSNotInExportMap',
      key: 'questionsJSNotInExportMap',
      sorter: (a, b) => a.questionsJSNotInExportMap - b.questionsJSNotInExportMap,
    },
    {
      title: "Questions of Type 'embeddedData', not Generated by JS, but in Export Map",
      dataIndex: 'questionsEmbeddedData',
      key: 'questionsEmbeddedData',
      sorter: (a, b) => a.questionsEmbeddedData - b.questionsEmbeddedData,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space direction="vertical">
          <Button onClick={() => downloadQuestionsCSV(record.name, record.dataSetInformation)}>
            Download Questions CSV
          </Button>
          <Button onClick={() => downloadOptionsCSV(record.name, record.dataSetInformation)}>
            Download Options CSV
          </Button>
          <Button onClick={() => downloadInstructionsTxt(record.name, record.dataSetInstructions)}>
            Download Stata Instructions Txt
          </Button>
        </Space>
      ),
    },
  ];

  const data = datasets.map((item) => ({
    key: item.name,
    name: item.name,
    completed: item.completed,
    questionsTotal: item.dataSetInformation.allQuestions.length,
    questionsJSInExportMap: item.dataSetInformation.allQuestions.filter(q => q.generatedByJS && q.inExportMap).length,
    questionsJSNotInExportMap: item.dataSetInformation.allQuestions.filter(q => q.generatedByJS && !q.inExportMap).length,
    questionsEmbeddedData: item.dataSetInformation.allQuestions.filter(q => q.questionType === 'embeddedData' && !q.generatedByJS && q.inExportMap).length,
    dataSetInformation: item.dataSetInformation,
    dataSetInstructions: item.dataSetInstructions,
  }));
  return (
    <div>
      <Space direction="vertical">
        <Typography>
          <Title level={4}>Usage instructions</Title>
          <Paragraph>
            This component is meant to automatically create questions, options and stata instructions files for different datasets exported from Qualtrics.<br />
            When selecting a folder, you need to select a folder that follows this structure:<br /><br />
            <Text code>
              - backup (folder)<br />
              -- name_of_survey (folder)<br />
              --- dataBackup (folder)<br />
              ---- name_of_survey_json.json (file)<br />
            </Text>
            <br /><br />
            The component will process the json file that has the structure from the survey from qualtrics and creates the corresponding files.<br />
            When you select the folder backup (surveyBackup, etc) the component will automatically loop through all the different surveys (folders) that are inside the corresponding survey.<br /><br />
            You can try by selecting the folder <Text code>Z:\mathlecxit_datamgt\surveyBackUps</Text>
          </Paragraph>
        </Typography>
        <Space>
          <Button onClick={selectFolder}>Select Folder</Button>
          {
            (!_.isEmpty(datasets) && (<><Button onClick={() => downloadAllCSVsAsZip(datasets)}>
              Download All Files PER SURVEY as Zip
            </Button>
            <Button onClick={() => downloadAllQuestionsAsCSV(datasets)}>
            Download All Questions as CSV
        </Button>
        <Button onClick={() => downloadAllOptionsAsCSV(datasets)}>
            Download All Options as CSV
        </Button></>
            ))
          }
        </Space>
        <Table
          columns={columns}
          dataSource={data}
          loading={loadingSelection}
          pagination={false}
        />
      </Space>
    </div>
  );
}
export default FolderSelector;

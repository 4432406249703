import { ProTable } from "@ant-design/pro-components";
import { useState } from "react";
import _ from 'lodash';
import { Button } from "antd";


const getStudentsTenant = (object, selectedId) =>{
    let answer = _.values(object[selectedId].students);
    
    return answer;
}


const StudentsTable = (props) => {

    const columns = [
        {
          title: "ID de la institución",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Nombres",
          dataIndex: "firstName",
          key: "firstName"
        },
        {
            title: "Apellidos",
            dataIndex: "lastName",
            key: "lastName"
          },
        {
          title: "Grupo",
          dataIndex: "group",
          key: "group"
        },
        {
            title: "No consent",
            dataIndex: "noConsent",
            key: "noConsent"
        },
        {
          title: "Acciones",
          key: "option",
          valueType: "option",
          render: (_, row) => [<a key="button-1" onClick={() => { }}>Editar nombre</a>, <a key="button-2" onClick={() => { }}>Ver estudiantes</a>],
        },
      ];



  return (
    <ProTable
      columns={columns}
      dataSource={getStudentsTenant(props.tenants, props.selectedSchool)}
      options={{
        search: true,
      }}
      rowKey="id"
      toolbar={{
        actions: [
          <Button key="primary" type="primary" onClick={()=> props.addStudents()}>
            Agregar estudiantes
          </Button>,
        ],
      }}
      search={false}
      dateFormatter="string"
      headerTitle={(props.tenants[props.selectedSchool])['name'] || ''}
    />
  );
};

export default StudentsTable;

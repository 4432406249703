import React, { useState } from 'react';
import { Button, message, Table, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const FileValidator = () => {
  const [errorData, setErrorData] = useState([]);
  const [visible, setVisible] = useState(false);
  const expectedColumnNames = ['curso', 'linea', 'nombre_estudiante'];

  const columns = [
    {
      title: 'Error',
      dataIndex: 'error',
      key: 'error',
    },
  ];

  const customRequest = ({ file, onSuccess, onError }) => {
    const handleValidation = async () => {
      const errors = [];
      if (!(await validateFileName(file.name))) {
        errors.push('Invalid file name format');
      }
      if (!(await validateCombinedCells(file))) {
        errors.push('El fichero tiene celdas combinadas');
      }
      if (!(await validateColumnNames(file))) {
        errors.push('El fichero no tiene las columnas necesarias: ' + expectedColumnNames.join(', '));
      }
      if (!(await validateSheetName(file))) {
        errors.push('El fichero no contiene la hoja: "Hoja 1"');
      }
      if (!(await validateSheetCount(file))) {
        errors.push('El fichero contiene más de una hoja');
      }

      if (errors.length > 0) {
        setErrorData(errors);
        setVisible(true);
        message.error('El fichero no tiene el formato correcto');
        onError('El fichero no tiene el formato correcto');
      } else {
        message.success(`${file.name} file uploaded successfully`);
        onSuccess();
      }
    };

    handleValidation();
  };

  const beforeUpload = file => {
    setErrorData([]); // Reset error data when a new file is selected
    setVisible(false); // Hide the error table when a new file is selected
    return true;
  };


  // Custom validation functions (now asynchronous)
  const validateFileName = async fileName => {
    // Implement your file name format validation logic here
    // Example: Check if the fileName follows a specific format
    return true; // Replace with your validation logic
  };

  const validateCombinedCells = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming you want to check the first sheet
        const mergedCells = worksheet['!merges']; // Check for merged cells
        resolve(!(mergedCells && mergedCells.length > 0));
      };
      reader.readAsBinaryString(file);
    });
  };

  const validateColumnNames = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming you want to check the first sheet
         // Replace with your expected column names
        const actualColumnNames = [];
        for (const key in worksheet) {
          if (key.startsWith('A')) {
            actualColumnNames.push(worksheet[key].v);
          }
        }
        resolve(expectedColumnNames.every(colName => actualColumnNames.includes(colName)));
      };
      reader.readAsBinaryString(file);
    });
  };

  const validateSheetName = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        resolve(sheetNames.length === 1 && sheetNames[0] === 'Hoja 1');
      };
      reader.readAsBinaryString(file);
    });
  };

  const validateSheetCount = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        resolve(sheetNames.length === 1);
      };
      reader.readAsBinaryString(file);
    });
  };

  return (
    <div>
       <Upload.Dragger
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        accept=".xlsx"
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
      <Table
        columns={columns}
        dataSource={errorData.map((error, index) => ({
          key: index,
          error,
        }))}
        pagination={false}
        bordered
        size="small"
        style={{ marginTop: '20px', display: visible ? 'block' : 'none' }}
      />
    </div>
  );
};

export default FileValidator;

import { PageContainer, ProCard, ProConfigProvider } from "@ant-design/pro-components";
import { Button, Modal, Form, Input } from "antd";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { functions } from "../utilities/Firebase";
import TenantFollowUpComponent from "./FollowupComponent";


import en from 'antd/locale/en_US';

const Manager = (props) => {
  
  const [tenants, setTenants] = useState(null);
  const [tab, setTab] = useState('1');
  const [isImportDrawerOpen, setIsImportDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRefreshOpen,setIsModalRefreshOpen] = useState(false);

  const [tabList, setTabList] = useState([
    {
      tab: "Instituciones Sur",
      key: 1,
      closable: false,
    },
    {
      tab: "Instituciones Catalunya",
      key: 2,
      closable: false,
    },
  ]);

  const changeTab = (key) => {
    setTab(key);
  }
  

  const loadData = () => {
    httpsCallable(
      functions,
      `getTenants`
    )({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
    }).then((r) => {
      console.log('loaded');
      setTenants(r.data);
    });
  };

  useEffect(() => {
    //loadData();
  }, []);



  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  
  

  return (
    <div
      style={{
        background: "#F5F7FA",
      }}
    >
      
      <PageContainer
        header={{
          title: "Tenant Follow Up",
          ghost: true,
        }}
        tabActiveKey={tab}
        tabList={[{
          tab: "Instituciones Sur",
          key: '1',
          closable: false,
        },
        {
          tab: "Instituciones Catalunya",
          key: '2',
          closable: false,
        }]}
        onTabChange={(key) => setTab(key)}
        
      >
        
        <ProCard direction="column" ghost gutter={[0, 16]}>
          <ProCard style={{ minHeight: 200 }}>
            {tab === '1' && <>
              <TenantFollowUpComponent scope='and' /></>
            }
            {tab === '2' && <><TenantFollowUpComponent scope='cat' /></>}
          </ProCard>
        </ProCard>
      </PageContainer>

      
    </div>
  );
};

export default Manager;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Divider, Collapse, Row, Col, Checkbox, Drawer, Tooltip } from 'antd';
import { LeftOutlined, DeleteColumnOutlined, EnvironmentOutlined, CloudServerOutlined, LayoutOutlined, MenuOutlined, LockOutlined, FilterOutlined, SplitCellsOutlined, SaveOutlined, InfoCircleOutlined, CheckOutlined, FrownOutlined } from '@ant-design/icons';
import './ExportConfigGen.css';
import EncryptingDynamicInputs from './Components/EncryptingDynamicInputs';
import FilteringDynamicInputs from './Components/FilteringDynamicInputs';
import DropColsDynamicInputs from './Components/DropColsDynamicInputs';
import SplittingDynamicInputs from './Components/SplittingDynamicInputs';

import TooltipTexts from './TooltipTexts';

const ExportConfigGen = () => {
    const [includeNoConsent, setIncludeNoConsent] = useState(false);
    const [jsonDrawerVisible, setJsonDrawerVisible] = useState(false);
    const [json, setJson] = useState(null);
    const [project, setProject] = useState('mathlecxit');
    const [dataSource, setDataSource] = useState('');
    const [dataSet, setDataSet] = useState('');
    const [currentVersion, setCurrentVersion] = useState('0000');
    const [currentVersionChangedBy, setCurrentVersionChangedBy] = useState('system');
    const [currentVersionDate, setCurrentVersionDate] = useState('');
    const [stateSourceType, setStateSourceType] = useState('local');
    const [endpoint, setEndpoint] = useState();
    const [statePayload, setStatePayload] = useState();
    const [sourcePath, setSourcePath] = useState(`/scratch/lab_mathlecxit/mathlecxit_datamgt/raw/${dataSource}/${dataSet}/`);
    const [encryptPath, setEncryptPath] = useState(`/scratch/lab_mathlecxit/mathlecxit_datamgt/encrypted/${dataSource}/${dataSet}/`);
    const [filterPath, setFilterPath] = useState(`/scratch/lab_mathlecxit/mathlecxit_datamgt/filtered/${dataSource}/${dataSet}/`);
    const [sortingPathPreRegion, setSortingPathPreRegion] = useState(`/scratch/lab_mathlecxit/shared_data/`);
    const [sortingPathPostRegion, setSortingPathPostRegion] = useState(`raw/admin/${dataSource}/${dataSet}/`);
    const [stateStringCols, setStateStringCols] = useState([]);
    const [stateTimestampCols, setStateTimestampCols] = useState([]);
    const [stateDropCols, setStateDropCols] = useState([]);
    const [stateEncryptionKey, setStateEncryptionKey] = useState();
    const [stateEncryptionFields, setStateEncryptionFields] = useState([]);
    const [stateFilterFields, setStateFilterFields] = useState([]);
    const [stateSortingRegion, setStateSortingRegion] = useState({});
    const [stateSplittingFields, setStateSplittingFields] = useState([]);

    const [existenceResultLoading, setExistenceResultLoading] = useState(false);
    const [existenceResultMessage, setExistenceResultMessage] = useState('');

    const { Panel } = Collapse;

    const handleEncryptingInputsChange = (updatedInputs) => {
        setStateEncryptionFields(updatedInputs);
    };

    const handleFilteringInputsChange = (updatedInputs) => {
        setStateFilterFields(updatedInputs);
    };

    const handleDropColsInputChange = (updatedInputs) => {
        setStateDropCols(updatedInputs);
    };

    const handleSortingInputsChange = (updatedInputs) => {
        setStateSplittingFields(updatedInputs);
    };

    const handleIncludeNoConsentAsFilter = (event) => {
        setIncludeNoConsent(event.target.checked);
    };

    const handleJsonDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([json], { type: 'application/json' });
        element.href = URL.createObjectURL(file);
        element.download = 'exportConfig.json';
        element.click();
        URL.revokeObjectURL(element.href);
    };

    const handleCheckExistence = async () => {
        setExistenceResultLoading(true);
        const successMessage = (
            <span style={{ fontStyle: 'italic', color: '#808080', marginLeft: '10px' }}>
                <CheckOutlined /> Config found
            </span>
        );
        const failMessage = (
            <span style={{ fontStyle: 'italic', color: '#808080', marginLeft: '10px' }}>
                <FrownOutlined /> Not found
            </span>
        );

        const endpoint = 'https://us-central1-lecxitmath.cloudfunctions.net/getCurrentDataSet';

        const payload = {
            token: 'AC335ED6B3FE8C936C7F6D6AD3D51',
            project,
            dataSource,
            dataSet
        };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();

                if (data['data']['currentExportConfig']) {
                    const currentExportConfig = data['data']['currentExportConfig'];
                    console.log(currentExportConfig);

                    setExistenceResultMessage(successMessage);

                    if (currentExportConfig['versioning']['currentVersion']) setCurrentVersion(currentExportConfig['versioning']['currentVersion']);
                    if (currentExportConfig['versioning']['currentVersionChangedBy']) setCurrentVersionChangedBy(currentExportConfig['versioning']['currentVersionChangedBy']);
                    if (currentExportConfig['versioning']['currentVersionDate']) setCurrentVersionDate(currentExportConfig['versioning']['currentVersionDate']);
                    if (currentExportConfig['sourceType']) setStateSourceType(currentExportConfig['sourceType']);
                    if (currentExportConfig['endpoint']) setEndpoint(currentExportConfig['endpoint']);
                    if (currentExportConfig['payload']) setStatePayload(JSON.stringify(currentExportConfig['payload']));
                    if (currentExportConfig['paths']['sourcePath']) setSourcePath(currentExportConfig['paths']['sourcePath']);
                    if (currentExportConfig['paths']['encryptPath']) setEncryptPath(currentExportConfig['paths']['encryptPath']);
                    if (currentExportConfig['paths']['filterPath']) setFilterPath(currentExportConfig['paths']['filterPath']);
                    if (currentExportConfig['paths']['sortingPathPreRegion']) setSortingPathPreRegion(currentExportConfig['paths']['sortingPathPreRegion']);
                    if (currentExportConfig['paths']['sortingPathPostRegion']) setSortingPathPostRegion(currentExportConfig['paths']['sortingPathPostRegion']);
                    if (currentExportConfig['stringCols']) setStateStringCols(currentExportConfig['stringCols']);
                    if (currentExportConfig['timestampCols']) setStateTimestampCols(currentExportConfig['timestampCols']);
                    if (currentExportConfig['dropCols']) setStateDropCols(currentExportConfig['dropCols']);
                    if (currentExportConfig['encryptionKey']) setStateEncryptionKey(currentExportConfig['encryptionKey']);
                    if (currentExportConfig['encryptionFields']) setStateEncryptionFields(currentExportConfig['encryptionFields']);
                    if (currentExportConfig['filterFields']) setStateFilterFields(currentExportConfig['filterFields']);
                    if (currentExportConfig['sortingRegion']) setStateSortingRegion(currentExportConfig['sortingRegion']);
                    if (currentExportConfig['sortingFields']) setStateSplittingFields(currentExportConfig['sortingFields']);

                } else {
                    setExistenceResultMessage(failMessage);
                }
            } else {
                setExistenceResultMessage(failMessage);
            }
        } catch (error) {
            // Handle fetch error here
        }

        setExistenceResultLoading(false);
    };

    const updatePaths = () => {
        let lab = '';
        let datamgt = '';
        let admin = '';

        if (project === 'mathlecxit') {
            lab = 'lab_mathlecxit';
            datamgt = 'mathlecxit_datamgt';
            admin = 'admin2';
        } else if (project === 'pentabilities') {
            lab = 'lab_pentabilities';
            datamgt = 'pentabilities_datamgt';
            admin = 'penta_admin2';
        } else if (project === 'quality') {
            lab = 'lab_quality';
            datamgt = 'quality_datamgt';
            admin = 'admin2';
        } else if (project === 'id') {
            lab = 'lab_id';
            datamgt = 'id_datamgt';
            admin = 'admin2';
        }

        if (project === 'mathlecxit' || project === 'pentabilities') {
            setSourcePath(`/scratch/${lab}/${datamgt}/raw/${dataSource}/${dataSet}/`);
            setEncryptPath(`/scratch/${lab}/${datamgt}/encrypted/${dataSource}/${dataSet}/`);
            setFilterPath(`/scratch/${lab}/${datamgt}/filtered/${dataSource}/${dataSet}/`);
            setSortingPathPreRegion(`/scratch/${lab}/shared_data/`);
            setSortingPathPostRegion(`/raw/${admin}/${dataSource}/${dataSet}/`);

        } else if (project === 'quality' || project === 'id') {
            setSourcePath(`C:\\scratch\\${lab}\\${datamgt}\\raw\\${dataSource}\\${dataSet}\\`);
            setEncryptPath(`C:\\scratch\\${lab}\\${datamgt}\\encrypted\\${dataSource}\\${dataSet}\\`);
            setFilterPath(`C:\\scratch\\${lab}\\${datamgt}\\filtered\\${dataSource}\\${dataSet}\\`);
            setSortingPathPreRegion(`C:\\scratch\\${lab}\\shared_data\\`);
            setSortingPathPostRegion(`\\raw\\${admin}\\${dataSource}\\${dataSet}`);
        }
    };

    // Call the updateSourcePath function whenever project, dataSource, or subfolder values change
    useEffect(() => {
        updatePaths();
    }, [project, dataSource, dataSet]);



    const onFinish = () => {

        let encryptFieldsForJson = [];
        let sortFieldsForJson = [];

        if (stateEncryptionFields !== [{ id: 1, includeInReport: '', field: '' }]) {
            if (stateEncryptionFields) encryptFieldsForJson = stateEncryptionFields.map(({ field, includeInReport }) => ({ field, includeInReport }));
        }

        const filterFieldsForJson = includeNoConsent ? [{ student: { noConsent: false } }] : [];

        if (stateSplittingFields !== [{ id: 1, fieldName: '', type: '', order: 1 }]) {
            if (stateSplittingFields) sortFieldsForJson = stateSplittingFields.map(({ fieldName, type, order }) => ({ fieldName, type, order }));
        }

        const exportFilesKey = dataSet; // Use state variable dataSet as the key

        const exportConfig = {
            dataSource: dataSource || "",
            exportFiles: {
                [exportFilesKey]: {
                    id: dataSet || "",
                    versioning: { currentVersion: currentVersion, currentVersionDate: currentVersionDate, currentVersionChangedBy: currentVersionChangedBy, configLastChanged: { date: "", change: "" } },
                    sourceType: stateSourceType || "",
                    ...(endpoint && statePayload && {
                        endpoint,
                        payload: JSON.parse(statePayload),
                    }),
                    paths: {
                        sourcePath: sourcePath || "",
                        encryptPath: encryptPath || "",
                        filterPath: filterPath || "",
                        sortingPathPreRegion: sortingPathPreRegion || "",
                        sortingPathPostRegion: sortingPathPostRegion || "",
                    },
                    stringCols: stateStringCols || [],
                    timestampCols: stateTimestampCols || [],
                    dropCols: stateDropCols || [],
                    encryptionKey: stateEncryptionKey || "",
                    encryptionFields: encryptFieldsForJson || [],
                    filterFields: filterFieldsForJson || [],
                    sortingRegion: stateSortingRegion || {},
                    sortingFields: sortFieldsForJson || [],
                },
            },
        };

        const json = JSON.stringify(exportConfig, null, 2);

        setJson(json);
        setJsonDrawerVisible(true);
    };



    return (
        <div className='page-container'>
            <div className='title-container'>
                <h1>Export Config Generator</h1>

                <Button type="default" className="json-button" onClick={() => setJsonDrawerVisible(!jsonDrawerVisible)}>
                    <LeftOutlined />
                </Button>
            </div>

            <div className='form-container '>
                <Form onFinish={onFinish}>

                    <Collapse defaultActiveKey={['1']} ghost>
                        <Panel header={<Divider orientation="left"><EnvironmentOutlined /> Location</Divider>} key="1">
                            <Form.Item name="project" label={
                                <span>
                                    Project
                                </span>
                            }>
                                <Select
                                    defaultValue="mathlecxit"
                                    style={{ width: 240 }}
                                    options={[
                                        { value: 'mathlecxit', label: 'Lecxit/Math' },
                                        { value: 'pentabilities', label: 'Pentabilities' },
                                        { value: 'quality', label: 'Quality' },
                                        { value: 'id', label: 'ID' }
                                    ]}
                                    onChange={value => setProject(value)}
                                />
                            </Form.Item>

                            <Form.Item name="dataSource" label={
                                <span>
                                    <Tooltip title={TooltipTexts.location.dataSource} placement="left">
                                        <InfoCircleOutlined style={{ marginRight: '8px' }} />
                                    </Tooltip>
                                    Data Source
                                </span>
                            }>
                                <Input onBlur={e => setDataSource(e.target.value)} />
                            </Form.Item>
                            <Form.Item name="subfolder" label={
                                <span>
                                    <Tooltip title={TooltipTexts.location.dataSet} placement="left">
                                        <InfoCircleOutlined style={{ marginRight: '8px' }} />
                                    </Tooltip>
                                    Data Set
                                </span>
                            }>
                                <Input onBlur={e => setDataSet(e.target.value)} />
                                <div style={{ marginTop: '8px' }}>
                                    <Button type="default" onClick={handleCheckExistence} loading={existenceResultLoading}>
                                        {existenceResultLoading ? 'Checking...' : 'Check if Exists'}
                                    </Button>
                                    {existenceResultMessage && <span style={{ fontStyle: 'italic', color: '#808080', marginLeft: '10px' }}>{existenceResultMessage}</span>}
                                </div>
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <Collapse ghost>
                        <Panel header={<Divider orientation="left"><CloudServerOutlined /> Type</Divider>} key="1">
                            <Form.Item name="sourceType" label={
                                <span>
                                    <Tooltip title={TooltipTexts.type} placement="left">
                                        <InfoCircleOutlined style={{ marginRight: '8px' }} />
                                    </Tooltip>
                                    Source Type
                                </span>
                            }>
                                <Select
                                    defaultValue={stateSourceType}
                                    style={{ width: 240 }}
                                    options={[
                                        { value: 'remote-versions', label: 'Remote (Versioning)' },
                                        { value: 'remote-concat', label: 'Remote (Appending)' },
                                        { value: 'local', label: 'Local' }
                                    ]}
                                    onChange={value => setStateSourceType(value)}
                                />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.sourceType !== currentValues.sourceType
                                }
                            >
                                {({ getFieldValue }) => {
                                    const sourceType = getFieldValue('sourceType');
                                    if (sourceType === 'remote-versions' || sourceType === 'remote-concat' || stateSourceType === 'remote-versions' || stateSourceType === 'remote-concat') {
                                        return (
                                            <>
                                                <Form.Item name="endpoint" label="Endpoint">
                                                    <Input addonBefore="http://" placeholder="www." defaultValue={endpoint} onBlur={e => setEndpoint(e.target.value)} />
                                                </Form.Item>
                                                <Form.Item name="payload" label="Payload">
                                                    <Input.TextArea style={{ minHeight: '50px' }} autoSize defaultValue={statePayload} onBlur={e => setStatePayload(e.target.value)} />
                                                </Form.Item>
                                            </>
                                        );
                                    }
                                    return null;
                                }}
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <Collapse ghost>
                        <Panel header={<Divider orientation="left"><LayoutOutlined /> Paths</Divider>} key="1">
                            <Form.Item name="sourcePath" label="Path to Raw Folder">
                                <Input defaultValue={sourcePath} onBlur={e => setSourcePath(e.target.value)} />
                            </Form.Item>
                            <Form.Item name="encryptPath" label="Path to Encrypted Folder">
                                <Input defaultValue={encryptPath} onBlur={e => setEncryptPath(e.target.value)} />
                            </Form.Item>
                            <Form.Item name="filterPath" label="Path to Filtered Folder">
                                <Input defaultValue={filterPath} onBlur={e => setFilterPath(e.target.value)} />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name="sortingPathPreRegion" label="Path to Shared Data (Before region split):" labelCol={{ span: 24 }}>
                                        <Input defaultValue={sortingPathPreRegion} onBlur={e => setSortingPathPreRegion(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="sortingPathPostRegion" label="Path to Shared Data (After region split):" labelCol={{ span: 24 }}>
                                        <Input defaultValue={sortingPathPostRegion} onBlur={e => setSortingPathPostRegion(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>

                    <Collapse ghost>
                        <Panel header={<Divider orientation="left"><MenuOutlined /> Fields</Divider>} key="1">
                            <Collapse ghost>
                                <Panel header={<div><LockOutlined style={{ marginRight: '4px' }} /> Encryption Fields </div>} key="1">
                                    <Form.Item name="encryptionKey" label="Encryption Key" initialValue={stateEncryptionKey}>
                                        <Select
                                            placeholder="Select Encryption Key"
                                            style={{ width: 240 }}
                                            options={[
                                                { value: 'firebaseId', label: 'firebaseId' },
                                                { value: 'idTea', label: 'idTea' },
                                                { value: 'idMonk', label: 'idMonk' },
                                                { value: 'idMonkGroup', label: 'idMonkGroup' },
                                                { value: 'idMonkStudent', label: 'idMonkStudent' },
                                                { value: 'idGroup', label: 'idGroup' },
                                                { value: 'id', label: 'id' }
                                            ]}
                                            onChange={value => setStateEncryptionKey(value)}
                                        />
                                    </Form.Item>

                                    <EncryptingDynamicInputs onInputChange={handleEncryptingInputsChange} updateData={stateEncryptionFields} />
                                </Panel>
                            </Collapse>

                            <Collapse ghost>
                                <Panel header={<div><FilterOutlined style={{ marginRight: '4px' }} /> Filter Fields </div>} key="1">
                                    <Form.Item>
                                        <Checkbox onChange={handleIncludeNoConsentAsFilter} >
                                            Include <code className="code-snippet">{JSON.stringify({ student: { noConsent: false } })}</code>
                                        </Checkbox>
                                    </Form.Item>
                                    <FilteringDynamicInputs onInputChange={handleFilteringInputsChange} />
                                </Panel>
                            </Collapse>

                            <Collapse ghost>
                                <Panel header={<div><DeleteColumnOutlined style={{ marginRight: '4px' }} /> Drop Columns </div>} key="1">
                                    <DropColsDynamicInputs onInputChange={handleDropColsInputChange} updateData={stateDropCols} />
                                </Panel>
                            </Collapse>

                            <Collapse ghost>
                                <Panel header={<div><SplitCellsOutlined style={{ marginRight: '4px' }} /> Splitting Fields </div>} key="1">
                                    <SplittingDynamicInputs onInputChange={handleSortingInputsChange} updateData={stateSplittingFields} />
                                </Panel>
                            </Collapse>
                        </Panel>
                    </Collapse>

                    <div className="submit-button-container">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>


            <Drawer
                title="Export Config JSON"
                open={jsonDrawerVisible}
                onClose={() => setJsonDrawerVisible(false)}
                width={800}
            >
                <Button type="primary" onClick={handleJsonDownload}>
                    <SaveOutlined /> Download JSON
                </Button>
                <pre>{json}</pre>
            </Drawer>
        </div>
    );
};

export default ExportConfigGen;


import 'antd/dist/reset.css';
import './App.css';

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

import Login from './Authentication/Login';
import Manager from './Management/Manager';
import PrivateRoute from './utilities/PrivateRoute';
import FollowUp from './Followup/TenantFollowup';
import DataSources from './DataSources/DataSources';
import surveyCheck from './SurveyCheck/surveyCheck';
import UserStore from './utilities/UserStore';
import ExportConfigGen from './ExportConfigGen/ExportConfigGen';
import ETL from './ETLDashboard/ETL';
import DocFollowUp from './DocFollowup/DocFollowUp';
import Codebooks from './Codebooks/Codebooks';
import { ConfigProvider } from 'antd';
import { checkUserRole } from "./utilities/rolesUtils"; // Import the role checking function


import enUS from 'antd/locale/en_US';
import FileValidator from './FileValidator/FileValidator';


const App = (props) => {
  const [userId, setUserId] = useState(null);
  const [userScope, setUserScope] = useState(null);
  const [userRole, setUserRole] = useState(Cookies.get("userRole") === undefined ? null : Cookies.get(`userRole`));
  const additionalProps = {
    configProvider: {
      theme: {
        token: {
          colorPrimary: '#112954'
        }
      }
    }
  };
  useEffect(() => {
    Cookies.get("userId") === undefined ? setUserId(null) : setUserId(Cookies.get(`userId`));
    let id = Cookies.get("userId") === undefined ? null : Cookies.get(`userId`);
    if (id !== null) {
      UserStore.loadData().then(() => {
        setUserScope(UserStore.getCurrentUserScope());

      })
    }
  }, []);

  return (
    <ConfigProvider locale={enUS} theme={additionalProps.configProvider.theme}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />

          <PrivateRoute
            exact
            path="/docs"
            component={DocFollowUp}
            userRole={userRole}
            allowedRoles={["admin", "viewer", "external"]} // Allow all roles to access this route
          />
          <PrivateRoute
            exact
            path="/validate"
            component={FileValidator}
            userRole={userRole}
            allowedRoles={["admin", "viewer", "external"]} // Allow all roles to access this route
          />
          <PrivateRoute
            exact
            path="/followup"
            component={FollowUp}
            userRole={userRole}
            allowedRoles={["admin", "viewer"]} // Allow only admin and viewer to access this route
          />
          <PrivateRoute
            exact
            path="/surveys"
            component={surveyCheck}
            userRole={userRole}
            allowedRoles={["admin", "viewer"]} // Allow only admin and viewer to access this route
          />
          <PrivateRoute
            exact
            path="/data"
            component={ETL}
            userRole={userRole}
            allowedRoles={["admin", "viewer"]} // Allow only admin and viewer to access this route
          />
          <PrivateRoute
            exact
            path="/export-config-generator"
            component={ExportConfigGen}
            userRole={userRole}
            allowedRoles={["admin", "viewer"]} // Allow only admin and viewer to access this route
          />
           <PrivateRoute
            exact
            path="/codebooks"
            component={Codebooks}
            userRole={userRole}
            allowedRoles={["admin", "viewer"]} // Allow only admin and viewer to access this route
          />
          <PrivateRoute
            exact
            path="/manager"
            component={Manager}
            userRole={userRole}
            allowedRoles={["admin"]} // Allow only admin to access this route
          />

          <Route exact path="/">
            <Redirect to="/docs" />
          </Route>
          <Route path="*">
            <Redirect to="/docs" />
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  )


}

export default App;

import React, { useState } from 'react';
import { Upload, Button, message, Typography, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { processStataInstructions, downloadInstructionsTxt } from './dataHelpers';

const { Title, Paragraph, Text, Link } = Typography;

const GeneratorComponent = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const handleFile1Upload = (file) => {
    // Check if the file contains the required columns
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const columns = result.meta.fields;
        if (!columns.includes('questionId') || !columns.includes('questionType') || !columns.includes('exportedQuestionText')) {
          message.error('File 1 is missing required columns: questionId, exportedQuestionText, questionType');
        } else {
          setFile1(result.data);
        }
      },
    });
  };

  const handleFile2Upload = (file) => {
    // Check if the file contains the required columns
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const columns = result.meta.fields;
        if (!columns.includes('questionId') || !columns.includes('choiceText') || !columns.includes('recode')) {
          message.error('File 2 is missing required columns: questionId, choiceText, recode');
        } else {
          setFile2(result.data);
        }
      },
    });
  };

  return (
    <div>
      <Space direction="vertical">
      <Typography>
        <Title level={4}>Usage instructions</Title>
        <Paragraph>
          This component is meant to automatically create stata instructions file from a questions and options csv files.<br />
          You need to upload both files and later will get a button to download the corresponding stata instructions.<br /><br />

          <Title level={4}>Minimum requirments of the files</Title>
          <Title level={5}>Questions file</Title>
          The file <Text type="warning">needs</Text> to have at least the following columns (the name of the columns should be exactly the same):
          <ul>
        <li>
          questionId: refers to the header that the data has (the exported data) the values in this column should correspond to the ones in the options file (e.g. "L_1_1")
        </li>
        <li>
          exportedQuestionText: refers to the text of the question (e.g. "¿Cuánto pasan las siguientes cosas durante las clases de matemáticas? - Hay mucho ruido en clase")
        </li>
        <li>
          questionType: refers to the type that the question has, currently is only use for processing the "embeddedData" questions.
        </li>
        </ul><br />
        <Title level={5}>Options file</Title>
          The file <Text type="warning">needs</Text> to have at least the following columns (the name of the columns should be exactly the same):
          <ul>
        <li>
          questionId: refers to the header that the data has (the exported data) the values in this column should correspond to the ones in the options file (e.g. "L_1_1")
        </li>
        <li>
          choiceText: refers to the text of the choice/option (e.g. "Nunca")
        </li>
        <li>
          recode: refers to the value of the choice/option (e.g. 1)
        </li>
        </ul>
         
        </Paragraph>
      </Typography>
        <Space>
      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={handleFile1Upload}
      >
        <Button icon={<UploadOutlined />}>Upload questions file (questionId, exportedQuestionText, questionType)</Button>
      </Upload>

      <Upload
        accept=".csv"
        showUploadList={false}
        beforeUpload={handleFile2Upload}
      >
        <Button icon={<UploadOutlined />}>Upload options file (questionId, choiceText, recode)</Button>
      </Upload>
      </Space>

      {/* Display a button if both files are correctly uploaded */}
      {file1 && file2 && (
        
        <Button type="primary" onClick={() => handleProcessing(file1, file2)}>
          Process Files
        </Button>
      )}    </Space>
     
    </div>
  );
};

// Function to process the files when both are uploaded with required columns
const handleProcessing = (file1, file2) => {
    const stataInstructions = processStataInstructions({allQuestions: file1, allOptions: file2});
    downloadInstructionsTxt('name', stataInstructions);
};

export default GeneratorComponent;

import { ProTable } from "@ant-design/pro-components";
import { useState } from "react";
import _ from 'lodash';


const getTenantArray = (object) =>{
    let answer = [];
    _.forEach(object, (school, key) => {
        const currentSchool = {id: key, name: school.name || '', num: (_.keys(school.students)).length || 0};
        answer.push(currentSchool);
    })
    return answer;
}
const SchoolsTable = (props) => {

    const columns = [
        {
          title: "ID de la institución",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Nombre de la institución",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "Número de estudiantes",
          dataIndex: "num",
          key: "num"
        },
      
       
        {
          title: "Acciones",
          key: "option",
          valueType: "option",
          render: (_, row) => [<a key="button-1" onClick={() => { props.changeName(row.id)}}>Editar nombre</a>, (<a key="button-2" onClick={() => { props.selectSchool(row.id)}}>Ver estudiantes</a>)],
        },
      ];



  return (
    <ProTable
      columns={columns}
      dataSource={getTenantArray(props.tenants)}
      options={{
        search: true,
      }}
      rowKey="id"
    
      search={false}
      dateFormatter="string"
      headerTitle="Listado de instituciones"
    />
  );
};

export default SchoolsTable;

import _ from "lodash";
import Cookies from "js-cookie";
import { auth, db } from "./Firebase";
import {ref, get, onValue} from 'firebase/database';
import { signOut } from "firebase/auth";

class UserStore{
    currentUser;
    currentUserId;
    async loadData(){
        this.currentUserId = Cookies.get('userId');
        if(_.isNull(this.currentUserId)) return null;

        await get(ref(db, `users/${this.currentUserId}`)).then(snapshot => {
            this.currentUser = snapshot.val();
        })
        .catch((e) => {
            if(e.toString().includes(`permission_denied`)){
                signOut(auth).then((y) => {
                    localStorage.clear();
                    Cookies.remove(`userId`);
                    return;
                })
            }
        })
    }

    getCurrentUserScope(){
        return _.isNull(this.currentUser.scope)?'nil':this.currentUser.scope;
    }

    getCurrentUserRole(){
        return _.isNull(this.currentUser.role)?'nil':this.currentUser.role;
    }

    getCurrentUserPermissions(){
        return {write:  _.isNull(this.currentUser.write)?false:this.currentUser.write, read: _.isNull(this.currentUser.read)?false:this.currentUser.read }
    }

    getCurrentUserId(){
        return this.currentUserId;
    }
    async logoutUser (){
        await(signOut(auth).then((r)=>{
            localStorage.clear();
            Cookies.remove("userId");
            window.location.replace("/");
        }))
    }
}

export default new UserStore();
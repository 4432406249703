import React, { useState, useEffect } from 'react';
import { httpsCallable } from "firebase/functions";
import { functions } from "../utilities/Firebase";
import { PageContainer, ProCard, ProConfigProvider } from "@ant-design/pro-components";
import { Row, Col, Badge, Dropdown, Space, Table, Collapse, Tabs, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileImage, AiOutlineFileWord, AiOutlineFileZip, AiOutlineFile, AiOutlineDownload } from 'react-icons/ai';
import UserStore from '../utilities/UserStore';
import Cookies from "js-cookie";
import _ from 'lodash';
import axios from 'axios';
import Papa from 'papaparse';


const items = [
    {
        key: '1',
        label: 'Action 1',
    },
    {
        key: '2',
        label: 'Action 2',
    },
];

const docTypes = [{ key: 'al', name: 'Attedance lists' }, { key: 'gl', name: 'Grades lists' }, { key: 'ag', name: 'Attendance & grades lists' }, { key: 'qr', name: 'Qualitative report' }, { key: 'ot', name: 'Other files' }]



const DocFollowUp = () => {
    const [fullData, setFullData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userRole, setUserRole] = useState(Cookies.get("userRole") === undefined ? null : Cookies.get(`userRole`));
    const [scope, setScope] = useState(Cookies.get("userScope") === undefined ? 'mec' : Cookies.get(`userScope`));
    const [allProjectTenants, setAllProjectTenants] = useState({mec:{}, pen:{}, all:{}});
    const [allTenantsFiles, setAllTenantFiles] = useState({mec:{}, pen:{}, all:{}});


    const postData = async (url, headers, body) => {
        let result = { status: 400, data: {} }
        try {
            const response = await axios.post(url, body, headers);
            result.status = 200;
            result.data = response.data;
        } catch (error) {
            result.statusCode = error.status;
            result.data = error;
        }
        return result;
    }



    const downloadInformation = async (nScope) => {

        setLoading(true);

        let currentAllProjectTenants = allProjectTenants;
        let currentAllTenantsFiles = allTenantsFiles;

        if((nScope === 'mec' || nScope === 'all') && _.isEmpty(currentAllProjectTenants['mec'])){
            currentAllProjectTenants['mec'] = await httpsCallable(functions, 'tempGetProjectTenantIds')({ scope: 'all', project:'mec', expanded: true });
        }
        if((nScope === 'mec' || nScope === 'all') && _.isEmpty(currentAllTenantsFiles['mec'])){
            currentAllTenantsFiles['mec'] = await httpsCallable(functions, 'tempGetAllFiles')({});
        }
        if((nScope === 'pen' || nScope === 'all') && _.isEmpty(currentAllProjectTenants['pen'])){
            currentAllProjectTenants['pen'] = await httpsCallable(functions, 'tempGetProjectTenantIds')({ scope: 'all', project:'pen', expanded: true });
            
        }
        if((nScope === 'pen' || nScope === 'all') && _.isEmpty(currentAllTenantsFiles['pen'])){
            currentAllTenantsFiles['pen'] = (await postData('https://us-central1-pentabilities-prod.cloudfunctions.net/tempGetAllFiles', { }, {"data": {"token": "c1ac2d20-19a3-401f-a3a1-f5f29ef91ba4"}}))['data'];
        }
        
        let tenantsToDisplay = {};
        let filesToDisplay = {};
        if(nScope !== 'all'){
            tenantsToDisplay = currentAllProjectTenants[nScope];
            filesToDisplay =  currentAllTenantsFiles[nScope];
        }
        else{
            tenantsToDisplay['data'] = []
            filesToDisplay['data'] = {files:[]};
            //let tempTenants = currentAllProjectTenants['pen']['data'];

            tenantsToDisplay['data'] = _.union(_.clone(currentAllProjectTenants['pen']['data']), _.clone(currentAllProjectTenants['mec']['data']));
            filesToDisplay['data']['files'] = _.union(_.clone(currentAllTenantsFiles['pen']['data']['files']), _.clone(currentAllTenantsFiles['mec']['data']['files']));
        }
        
        setAllProjectTenants(currentAllProjectTenants);
        setAllTenantFiles(currentAllTenantsFiles);

        await processInformation(tenantsToDisplay, filesToDisplay);

        setLoading(false);
    }

    const processInformation = async (allProjectTenants, allTenantsFiles) => {

        let allTenantIds = allProjectTenants.data;
        const grouppedTenantFiles = _.groupBy((allTenantsFiles.data.files), 'school');

        _.forEach(allTenantIds, (value, key) => {
            let currentTenant = value;
            currentTenant.totalFiles = _.has(grouppedTenantFiles, currentTenant.tenantId) ? (grouppedTenantFiles[currentTenant.tenantId]).length : 0;
            let filesByGroup = _.groupBy((grouppedTenantFiles[currentTenant.tenantId]), 'group');
            let allGroupsKeys = _.union(currentTenant.groupsList, _.keys(filesByGroup));
            let filesPerGroup = [];

            _.forEach(allGroupsKeys, (groupKey) => {
                let groupObject = { group: groupKey, totalFiles: _.has(filesByGroup, groupKey) ? (filesByGroup[groupKey]).length : 0, allFiles: _.has(filesByGroup, groupKey) ? filesByGroup[groupKey] : [] };
                _.forEach(docTypes, (docType) => {
                    let docArray = _.has(filesByGroup, groupKey) ? _.has(_.groupBy(filesByGroup[groupKey], 'fileType'), docType.key) ? (_.groupBy(filesByGroup[groupKey], 'fileType'))[docType.key] : [] : [];
                    //groupObject[docType.key+'Files'] = docArray
                    groupObject['total' + docType.key] = docArray.length;
                })
                let object = { alFiles: _.has(filesByGroup, groupKey) ? _.has(_.groupBy(filesByGroup[groupKey], 'fileType'), 'al') ? (_.groupBy(filesByGroup[groupKey], 'fileType'))['al'] : [] : [] }
                //if(currentTenant.tenantId === '105') console.log(object);


                filesPerGroup.push(groupObject)
            })
            currentTenant.filesPerGroup = filesPerGroup;

            allTenantIds[key] = currentTenant;
        })

        console.log(allTenantIds);

        setFullData(allTenantIds);

    }
  
    useEffect(() => {
        downloadInformation(scope);
    }, []);

    const getFileTypeName = (nKey) => {
        return ((_.filter(docTypes, { key: nKey }))[0])['name'];
    }
    const changeScope = (newScope) => {
        
        downloadInformation(newScope);
    }

    const processContentType = (nContentType) => {


        switch (nContentType) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <AiOutlineFileExcel size={22} />;
                break;
            case "application/vnd.ms-excel":
                return <AiOutlineFileExcel size={22} />;
                break;
            case "application/pdf":
                return <AiOutlineFilePdf size={22} />;
                break;
            case "image/png":
                return <AiOutlineFileImage size={22} />;
                break;
            default:
                return <AiOutlineFile size={22} />;
        }






    }

    const expandedRowRender = (record, index, indent, expanded) => {

        const columns = [
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
            },
            {
                title: 'Total files',
                dataIndex: 'totalFiles',
                key: 'totalFiles',
            },
        ];

        _.forEach(docTypes, (docType) => {
            columns.push({ title: 'Total ' + (docType.name).toLocaleLowerCase(), key: docType.key, dataIndex: 'total' + docType.key })
        })


        return <Table size='small' columns={columns} rowKey={'group'} expandable={{ expandedRowRender: filesExandedRender }} dataSource={record['filesPerGroup']} pagination={false} />;
    };

    const filesExandedRender = (record, index, indent, expanded) => {
        console.log(record)
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Type',
                key: 'fileType',
                render: (_, { fileType }) => (<>{getFileTypeName(fileType)}</>)
            },
            {
                title: 'Uploaded',
                key: 'timeCreated',
                render: (_, { timeCreated }) => (<>{timeCreated.substring(0, 10)}</>)
            },
            {
                title: 'Content type',
                key: 'contentType',
                render: (_, { contentType }) => (<>{processContentType(contentType)}</>)
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (_, { downloadLink }) => (<a href={downloadLink}><AiOutlineDownload size={22} /></a>)
            }
        ]
        return <Table size='small' columns={columns} rowKey={'name'} dataSource={record['allFiles']} />

    }
    const columns = [
        {
            title: 'School ID',
            dataIndex: 'tenantId',
            key: 'tenantId',
        },
        {
            title: 'Total files',
            dataIndex: 'totalFiles',
            key: 'totalFiles',
        },

    ];

    const totalExcelFilesInArray = (nArray) => {
        let answer = 0;
        for(let u=0; u<nArray.length;u++){
            let currentFile  = nArray[u];
            if(currentFile['contentType'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || currentFile['contentType'] === "application/vnd.ms-excel") answer++;
        }
        return answer;
    }

    const totalPDFFilesInArray = (nArray) => {
        let answer = 0;
        for(let u=0; u<nArray.length;u++){
            let currentFile  = nArray[u];
            if(currentFile['contentType'] === "application/pdf") answer++;
        }
        return answer;
    }

    const exportToCSV = () => {
        // Your data structure here
        const data = fullData;
        
        // Prepare the data for CSV export
        const csvData = [];
        csvData.push([
            'Tenant ID', 
            'Group', 
            'Total A&G List', 
            'Total Excel in A&G List', 
            'Total PDF in A&G List', 
            'Total Attendance List', 
            'Total Excel in Attendance List',
            'Total PDF in Attendance List',
            'Total Grades List',
            'Total Excel in Grades List', 
            'Total PDF in Grades List', 
            'Total other files', 
            'Total Excel in other files',
            'Total PDF in other files',
            'Total Qualitative Reports', 
            'Total Excel in Qualitative Reports files',
            'Total PDF in Qualitative Reports files',
            'Total files',
            'Total Excel in files',
            'Total PDF in files',
        ]);
        
        data.forEach(tenant => {
          tenant.groupsList.forEach(group => {
            let allGroupFiles = tenant.filesPerGroup.find(fileGroup => fileGroup.group === group)?.allFiles || [];
            let groupExcelFiles = 0;
            for(let u=0; u<allGroupFiles.length;u++){
                let currentFile = allGroupFiles[u];
                if(currentFile['contentType'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || currentFile['contentType'] === "application/vnd.ms-excel") groupExcelFiles++;
            }

            let sortedFiles = (_.groupBy(allGroupFiles,'fileType'));


            
            const groupData = [
              tenant.tenantId,
              group,
              _.has(sortedFiles,'ag')?(_.get(sortedFiles,'ag')).length:0,
              totalExcelFilesInArray(_.has(sortedFiles,'ag')?(_.get(sortedFiles,'ag')):[]),
              totalPDFFilesInArray(_.has(sortedFiles,'ag')?(_.get(sortedFiles,'ag')):[]),
              _.has(sortedFiles,'al')?_.get(sortedFiles,'al').length:0,
              totalExcelFilesInArray(_.has(sortedFiles,'al')?(_.get(sortedFiles,'al')):[]),
              totalPDFFilesInArray(_.has(sortedFiles,'al')?(_.get(sortedFiles,'al')):[]),
              _.has(sortedFiles,'gl')?_.get(sortedFiles,'gl').length:0,
              totalExcelFilesInArray(_.has(sortedFiles,'gl')?(_.get(sortedFiles,'gl')):[]),
              totalPDFFilesInArray(_.has(sortedFiles,'gl')?(_.get(sortedFiles,'gl')):[]),
              _.has(sortedFiles,'ot')?_.get(sortedFiles,'ot').length:0,
              totalExcelFilesInArray(_.has(sortedFiles,'ot')?(_.get(sortedFiles,'ot')):[]),
              totalPDFFilesInArray(_.has(sortedFiles,'ot')?(_.get(sortedFiles,'ot')):[]),
              _.has(sortedFiles,'qr')?_.get(sortedFiles,'qr').length:0,
              totalExcelFilesInArray(_.has(sortedFiles,'qr')?(_.get(sortedFiles,'qr')):[]),
              totalPDFFilesInArray(_.has(sortedFiles,'qr')?(_.get(sortedFiles,'qr')):[]),
              allGroupFiles.length || 0,
              totalExcelFilesInArray(allGroupFiles || []),
              totalPDFFilesInArray(allGroupFiles ||[])
            ];
            csvData.push(groupData);
          });
        });

        console.log(csvData);
  
        // Create a CSV string
        const csvString = Papa.unparse(csvData);
  
        // Create a Blob and download the file
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'tenant_groups.csv';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
  
    




    return (
        <div
            style={{
                background: "#F5F7FA",
            }}
        >
            <PageContainer header={{ title: "Administrative files", ghost: true }}>
                <Row style={{ width: '100%' }}>
                    {scope === 'all' && ( <Tabs
                        defaultActiveKey={scope}
                        type="card"
                        size='large'
                        onChange = {(selected) => {changeScope(selected)}}
                        items={[{ label: 'All projects', key: 'all'}, {label: 'Lexcit/Math', key: 'mec'}, {label:'Pentabilities', key: 'pen'}]}
        /> )}
                    
                    <Col span={24}>
                        <ProCard style={{ width: '100%', minHeight: 'calc(100vh - 300px)' }} loading={loading}>
                            <Collapse defaultActiveKey={['1']}>
                                <Collapse.Panel header={"At least 1 file received (total schools: "+(_.reject(fullData, { totalFiles: 0 })).length+ ")"} key="1" extra={<Button onClick={() => exportToCSV()}> Export summary </Button>}>
                                    
                                    <Table size='small' rowKey={'tenantId'} columns={columns} dataSource={_.reject(fullData, { totalFiles: 0 })} expandable={{
                                        expandedRowRender,
                                        defaultExpandedRowKeys: ['0'],
                                    }} />
                                </Collapse.Panel>
                                <Collapse.Panel header={"No files available (total schools: "+(_.filter(fullData, { totalFiles: 0 })).length+")"} key="2">
                                    <Table size='small' rowKey={'tenantId'} columns={columns} dataSource={_.filter(fullData, { totalFiles: 0 })} />
                                </Collapse.Panel>
                            </Collapse>
                        </ProCard>
                    </Col>
                </Row>

            </PageContainer>
        </div>
    )

}

export default DocFollowUp;
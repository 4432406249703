import React, { useEffect } from "react";

import { CloseCircleOutlined } from "@ant-design/icons";
import { Radio, Result, Space, Typography } from "antd";

import ImportStudentsStore from "./ImportStudentsStore";

import { filter, keys, map, uniqBy } from "lodash";

const { Text, Paragraph } = Typography;

const Results = ({ actionEmails, actionGroups, setActionEmails, setActionGroups }) => {
  const { errorMessages, message, setMessage } = ImportStudentsStore;

  if (keys(errorMessages).length > 0) ImportStudentsStore.setStatus("warning");

  const groupErrors = filter(errorMessages, (message) => message.type === "groups");
  const emailErrors = filter(errorMessages, (message) => message.type === "email");

  useEffect(() => {
    if (errorMessages.length > 0) setMessage("StudentsManagement.warningMessage");
    else setMessage("StudentsManagement.successMessage");
  }, []);

  return (
    <Space direction="vertical">
      {groupErrors.length > 0 && (
        <Result title={"warning message"} status={"warning"}>
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                "groups does not exist"
              </Text>
            </Paragraph>

            {map(uniqBy(groupErrors, "value"), (error) => {
              const { value } = error;
              return (
                <Paragraph>
                  <Space>
                    <CloseCircleOutlined className="site-result-demo-error-icon" />
                    {value}
                  </Space>
                </Paragraph>
              );
            })}

            <Radio.Group onChange={(e) => setActionGroups(e.target.value)} value={actionGroups}>
              <Space>
                <Radio value={"ignore"}>Ignore records</Radio>
                <Radio value={"force"}>Update records</Radio>
              </Space>
            </Radio.Group>
          </div>
        </Result>
      )}

      {emailErrors.length > 0 && (
        <Result title={"warning message"} status={"warning"}>
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                "email exist in the system"
              </Text>
            </Paragraph>

            {map(uniqBy(emailErrors, "value"), (error) => {
              const { value } = error;

              return (
                <Paragraph>
                  <Space>
                    <CloseCircleOutlined className="site-result-demo-error-icon" />
                    {value}
                  </Space>
                </Paragraph>
              );
            })}

            <Radio.Group onChange={(e) => setActionEmails(e.target.value)} value={actionEmails}>
              <Space>
                <Radio value={"ignore"}>Ignore records</Radio>
                <Radio value={"force"}>Update records</Radio>
              </Space>
            </Radio.Group>
          </div>
        </Result>
      )}

      {errorMessages.length === 0 && <Result title={"success message"} status={"success"}></Result>}
    </Space>
  );
};

export default Results;

import React, { useState } from "react";

import { Button, Row } from "antd";
import ImportStudentsStore from "./ImportStudentsStore";

const FooterImportDrawer = ({ status, nextStep, previousStep, step, actionGroups, actionEmails, showRefreshModal }) => {
  const { uploadedSpreadsheetData, unmatchedFields } = ImportStudentsStore;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {step === 1 && (
        <Row className="upload-drawer-buttons">
          <Button
            onClick={() => {
              ImportStudentsStore.setUploadedSpreadsheetData(null);
              previousStep();
            }}
            type="primary"
            size={"large"}
          >
            Back
          </Button>

          <Button
            onClick={() => {
              nextStep();
            }}
            type="primary"
            disabled={unmatchedFields ? true : false}
            size={"large"}
            loading={ImportStudentsStore.isLoading}
          >
            Next
          </Button>
        </Row>
      )}

      {step === 2 && (
        <Row className="upload-drawer-buttons">
          <Button
            onClick={() => {
              previousStep();
            }}
            type="primary"
            size={"large"}
          >
            Back
          </Button>
          {}
          <Button
            onClick={() => {
              ImportStudentsStore.createUsersFromSpreadsheet();
              nextStep();
              showRefreshModal();
            }}
            type="primary"
            disabled={status === "error" ? true : false}
            size={"large"}
            loading={isLoading}
          >
            Finalize
          </Button>
        </Row>
      )}
    </>
  );
};

export default FooterImportDrawer;

import React, { useState, useEffect } from 'react';
import { Select, Button, Row, Col, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const EncryptingDynamicInputs = ({ onInputChange, updateData }) => {
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (Array.isArray(updateData) && updateData.length > 0) {
      setInputs(updateData);
    } else {
      setInputs([{ id: 1, includeInReport: '', field: '' }]);
    }
  }, [updateData]);

  const handleInputChange = (index, key, value) => {
    const updatedInputs = inputs.map((input, i) =>
      i === index ? { ...input, [key]: value } : input
    );
    setInputs(updatedInputs);
    onInputChange(updatedInputs); // Pass the updated inputs to the parent component
  };

  const handleAddInput = () => {
    const newInput = { id: inputs.length + 1, includeInReport: '', field: '' };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = inputs.filter((input, i) => i !== index);
    setInputs(updatedInputs);
    onInputChange(updatedInputs); // Update the parent component with the updated inputs
  };

  return (
    <div>
      <Button onClick={handleAddInput}> <PlusOutlined /> </Button>
      {inputs.map((input, index) => (
        <div key={index}>
          <Row gutter={16}>
            <Col span={6}>
              <Input
                placeholder="Field Name"
                onBlur={(event) =>
                  handleInputChange(index, 'field', event.target.value)
                }
                value={input.field}
                onChange={(event) =>
                  handleInputChange(index, 'field', event.target.value)
                }
              />
            </Col>
            <Col span={6}>
              <Select
                onChange={(value) =>
                  handleInputChange(index, 'includeInReport', value)
                }
                style={{ width: '100%' }}
                placeholder="Include in Report?"
                value={input.includeInReport}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Col>
            {inputs.length > 1 && (
              <Col span={2}>
                <Button onClick={() => handleRemoveInput(index)}>
                  <DeleteOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default EncryptingDynamicInputs;

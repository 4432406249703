import React, { useState } from "react";
import { Card, Space, Col, Row, Badge, Tooltip, Tabs } from 'antd';
import { DownloadOutlined, LockOutlined, FilterOutlined, SplitCellsOutlined, SettingOutlined, ExclamationOutlined, InfoCircleOutlined, TableOutlined } from '@ant-design/icons'
import './CardsPanel.css'
import ConfigInfoModal from "./ConfigInfoModal";


const CardsPanel = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState(false);

  const [isDroppedColsModalOpen, setIsDroppedColsModalOpen] = useState(false);
  const [droppedColsModalId, setDroppedColsModalId] = useState(false);

  const [currentConfig, setCurrentConfig] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const cardsData = props.currentDataSet
  const stepOneData = cardsData.step1_dwnld_versions
  const stepTwoData = cardsData.step2_encrypt
  const stepThreeData = cardsData.step3_filter
  const stepFourData = cardsData.step4_split
  const configData = cardsData.currentExportConfig
  const totalVersions = configData.versioning.currentVersion;
  const formatNumber = props.formatNumber

  const { TabPane } = Tabs;


  //show Modal (configuration pop up window) for a particular step, once 'View configuration' is clicked
  const showModal = (event) => {
    setIsModalOpen(true);
    setModalId(event.currentTarget.id);
    setCurrentConfig(configData)
    //console.log('id', event.currentTarget.id)
  };

  //when the cancel button is clicked, or when user clicks outside the pop up window, reset the states
  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalId('');
    // console.log('modalId', modalId)
  };

  const showDroppedColsModal = (event) => {
    setIsDroppedColsModalOpen(true);
    setDroppedColsModalId(event.currentTarget.id);

    if (event.currentTarget.id === 'droppedCols') {
      setModalContent(cardsData.step3_filter)

    } else if (event.currentTarget.id == 'autoDroppedCols') {
      setModalContent(cardsData.step3_filter)

    } else if (event.currentTarget.id === 'RawColNames') {
      setModalContent(cardsData.step1_dwnld_versions)

    } else if (event.currentTarget.id === 'unmatchedUniqueIDs') {
      setModalContent(cardsData.step2_encrypt)

    } else {
      setModalContent(null)
    }
    // console.log('id', event.currentTarget.id)
  };

  const handleDroppedColsModalClose = () => {
    setIsDroppedColsModalOpen(false);
    setDroppedColsModalId('');
    // console.log('modalId', modalId)
  };

  //generate the runtime (function to be called at the bottom of each card to display the runtime)
  const getRuntime = (begin, end) => {
    // console.log('begin and end', begin, 'and', end)
    const startTime = new Date(begin);
    const endTime = new Date(end);
    //  console.log('startTime and endTime', startTime, 'and', endTime)
    const runtimeInMilliseconds = endTime.getTime() - startTime.getTime();
    const runtimeInSeconds = runtimeInMilliseconds / 1000;
    const runtimeInMinutes = runtimeInSeconds / 60;

    const hours = Math.floor(runtimeInMinutes / 60);
    const minutes = Math.round(runtimeInMinutes % 60);
    const seconds = Math.floor(runtimeInSeconds % 60);

    return (`${hours} h ${minutes} min ${seconds} s`);
  }

  // const updateBackgroundColor = (status) => {
  //   return status === 'OK' ? '' 
  //        : status === 'KO'? '#ffccc7'
  //        : status === 'PENDING' ? '#ffe7ba'
  //        : '#f0f0f0'
  // }


  // RETURN PANEL

  return (

    <div className="cardPanel">
      {configData && configData.id && cardsData && cardsData.currentOverview && cardsData.currentOverview.latestExecutionDate ?
        <Row key={`heading-${configData.id}`}>
          <div>
            <h3>{`\u00A0\u00A0${configData.id}\u00A0 - \u00A0  Last execution date: \u00A0${cardsData.currentOverview.latestExecutionDate}`}</h3>
          </div>
        </Row>
        : ''}

      <Row key={props.rowKey} gutter={16} type="flex">

        {/* STEP 1 (DOWNLOAD) CARD */}
        <Col className="cardCol" key='step1Col' span={6}>
          <Card className="customCard" key='step1Card'
            style={{
              backgroundColor:
                stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'OK' ? ''
                  : stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'KO' ? '#ffccc7'
                    : stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'PENDING' ? '#ffe7ba'
                      : '#f0f0f0'
            }}
            headStyle={{
              backgroundColor:
                stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'OK' ? '#1677ff'
                  : stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'KO' ? '#ff4d4f'
                    : stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'PENDING' ? '#ffa940'
                      : '#bfbfbf',
              color: '#FFFFFF' // Set the text color of the head
            }}
            title={
              <Space>
                <DownloadOutlined />
                Download
                {stepOneData && stepOneData.stepStatus && stepOneData.stepStatus === 'KO' ?
                  <div style={{ height: '25px', width: '25px', backgroundColor: '#8080804D', borderRadius: '50%' }}>
                    <Tooltip title={stepOneData.errorMessage}>
                      <ExclamationOutlined />
                    </Tooltip>
                  </div>
                  : ''}
              </Space>
            }

            actions={[
              <Tooltip title="View Configuration">
                <SettingOutlined id="DownloadConfig" onClick={showModal} />
              </Tooltip>,
              <Tooltip title="View Column Names">
                <TableOutlined id="RawColNames" onClick={showDroppedColsModal} />
              </Tooltip>
            ]}
            bordered={false}
          >
            <div>
              <Space direction="vertical" size="small" style={{ display: 'flex' }}>

                <div>
                  <p>Total versions:</p>
                  {configData && totalVersions && <h3>{formatNumber(totalVersions)}</h3>}
                </div>

                <div>
                  <p>Last version:</p>
                  {configData && configData.versioning && configData.versioning.currentVersionDate ? (
                    <h3 className="positive">{((configData.versioning.currentVersionDate).split(' '))[0]}</h3>
                  ) : (
                    <h3 className="positive">N/A</h3>
                  )}
                </div>

                <div>
                  <p>Changed by:</p>
                  {stepOneData && stepOneData.currentVersionChangedBy ?
                    <h3>{stepOneData.currentVersionChangedBy}</h3> : <h3>N/A</h3>}
                </div>

                <div>
                  <p>Source:</p>
                  {stepOneData && stepOneData.type ?
                    <h3>{stepOneData.type}</h3> : <h3>N/A</h3>}
                </div>

                <div>
                  {/* Number of rows and columns per version */}
                  {stepOneData && stepOneData.localFile && stepOneData.remoteFile && stepOneData.localFile.columnNumber && stepOneData.localFile.rowNumber && stepOneData.remoteFile.columnNumber && stepOneData.remoteFile.rowNumber ?
                    <table className="difference">
                      <thead>
                        <tr>
                          <th className='description'>Rows/cols in version</th>
                          <th>Rows</th>
                          <th>Columns</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Original</th>
                          <td>{formatNumber(stepOneData.localFile.rowNumber)}</td>
                          <td>{formatNumber(stepOneData.localFile.columnNumber)}</td>
                        </tr>
                        <tr>
                          <th>New</th>
                          <td className="positive"><b>{formatNumber(stepOneData.remoteFile.rowNumber)}</b></td>
                          <td>{formatNumber(stepOneData.remoteFile.columnNumber)}</td>
                        </tr>
                      </tbody>
                    </table>
                    :
                    stepOneData && stepOneData.localFile && stepOneData.localFile.columnNumber && stepOneData.localFile.rowNumber ?
                      <table className="difference">
                        <thead>
                          <tr>
                            <th className='description'>Rows/cols in version</th>
                            <th>Rows</th>
                            <th>Columns</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Original</th>
                            <td>{formatNumber(stepOneData.localFile.rowNumber)}</td>
                            <td>{formatNumber(stepOneData.localFile.columnNumber)}</td>
                          </tr>
                        </tbody>
                      </table>
                      :
                      <div>
                        <p className='description'>No. pre/post execution</p>
                        <h3>N/A</h3>
                      </div>}
                </div>

                <div>
                  <p>Runtime:</p>
                  {stepOneData && stepOneData.stepBeginTime && stepOneData.stepEndTime ? (
                    <h3>{getRuntime(stepOneData.stepBeginTime, stepOneData.stepEndTime)}</h3>) : <h3>N/A</h3>}
                </div>
              </Space>
            </div>
          </Card>
        </Col>


        {/* STEP 2 (ENCRYPT) CARD */}
        <Col className="cardCol" key='step2Col' span={6}>
          <Card className="customCard" key='step2Card'
            style={{
              backgroundColor:
                stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'OK' ? ''
                  : stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'KO' ? '#ffccc7'
                    : stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'PENDING' ? '#ffe7ba'
                      : '#f0f0f0'
            }}
            headStyle={{
              backgroundColor:
                stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'OK' ? '#1677ff'
                  : stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'KO' ? '#ff4d4f'
                    : stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'PENDING' ? '#ffa940'
                      : '#bfbfbf',
              color: '#FFFFFF' // Set the text color of the head
            }}
            title={
              <Space>
                <LockOutlined />
                Encrypt
                {stepTwoData && stepTwoData.stepStatus && stepTwoData.stepStatus === 'KO' ?
                  <div style={{ height: '25px', width: '25px', backgroundColor: '#8080804D', borderRadius: '50%' }}>
                    <Tooltip title={stepTwoData.errorMessage}>
                      <ExclamationOutlined />
                    </Tooltip>
                  </div>
                  : ''}
              </Space>
            }
            actions={[
              <div className="ConfigFooter" id="EncryptConfig" onClick={showModal}>
                <SettingOutlined />
                <span>  View configuration</span>
              </div>
            ]}
            bordered={false}
          >
            <div>
              <Space direction="vertical" size="small" style={{ display: 'flex' }}>

                <div>
                  <p>Matched unique IDs:</p>
                  {stepTwoData && stepTwoData.encryptReport && stepTwoData.encryptReport.uniqueNoOfEncryptedUniqueIds ?
                    <h3 className="positive">{formatNumber(stepTwoData.encryptReport.uniqueNoOfEncryptedUniqueIds)}</h3> : <h3>N/A</h3>}
                </div>

                <div>
                  <p>No. of columns to be encrypted:</p>
                  {stepTwoData && stepTwoData.encryptReport && stepTwoData.encryptReport.consideredEncCols ?
                    <h3>{formatNumber(stepTwoData.encryptReport.consideredEncCols.length)}</h3> : <h3>N/A</h3>}
                </div>

                <div>
                    <p>Unmatched unique IDs:</p>
                    <span style={{ marginLeft: '10px' }}>
                      <Tooltip title="Click to see which IDs were not encrypted because they couldn't be found in the database" >
                        <a id="unmatchedUniqueIDs" onClick={showDroppedColsModal} >
                          <InfoCircleOutlined />
                        </a>
                      </Tooltip>
                    </span>
                  {stepTwoData && stepTwoData.encryptReport && stepTwoData.encryptReport.uniqueUnencryptedUniqueIds ? 
                    <h3>{formatNumber(stepTwoData.encryptReport.uniqueUnencryptedUniqueIds.length)}</h3> 
                    : <h3>N/A</h3>}
                </div>

                <div>
                  {stepTwoData && stepTwoData.afterEncryption && stepTwoData.beforeEncryption && stepTwoData.afterEncryption.columnNumber && stepTwoData.afterEncryption.rowNumber && stepTwoData.beforeEncryption.columnNumber && stepTwoData.beforeEncryption.rowNumber ?
                    <table className="difference">
                      <thead>
                        <tr>
                          <th className='description'>No. pre/post execution</th>
                          <th>Rows</th>
                          <th>Columns</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Before</th>
                          <td>{formatNumber(stepTwoData.beforeEncryption.rowNumber)}</td>
                          <td>{formatNumber(stepTwoData.beforeEncryption.columnNumber)}</td>
                        </tr>
                        <tr>
                          <th>After</th>
                          <td>{formatNumber(stepTwoData.afterEncryption.rowNumber)}</td>
                          <td>{formatNumber(stepTwoData.afterEncryption.columnNumber)}</td>
                        </tr>
                      </tbody>
                    </table>
                    :
                    <div>
                      <p className='description'>No. pre/post execution</p>
                      <h3>N/A</h3>
                    </div>}
                </div>

                <div>
                  <p>Runtime:</p>
                  {stepTwoData && stepTwoData.stepBeginTime && stepTwoData.stepEndTime ? (
                    <h3>{getRuntime(stepTwoData.stepBeginTime, stepTwoData.stepEndTime)}</h3>) : <h3>N/A</h3>}
                </div>
              </Space>
            </div>
          </Card>
        </Col>


        {/* STEP 3 (FILTER) CARD */}
        <Col className="cardCol" key='step3Col' span={6}>
          <Card className="customCard" key='step3Card'
            style={{
              backgroundColor:
                stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'OK' ? ''
                  : stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'KO' ? '#ffccc7'
                    : stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'PENDING' ? '#ffe7ba'
                      : '#f0f0f0'
            }}
            headStyle={{
              backgroundColor:
                stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'OK' ? '#1677ff'
                  : stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'KO' ? '#ff4d4f'
                    : stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'PENDING' ? '#ffa940'
                      : '#bfbfbf',
              color: '#FFFFFF' // Set the text color of the head
            }}
            title={
              <Space>
                <FilterOutlined />
                Filter
                {stepThreeData && stepThreeData.stepStatus && stepThreeData.stepStatus === 'KO' ?
                  <div style={{ height: '25px', width: '25px', backgroundColor: '#8080804D', borderRadius: '50%' }}>
                    <Tooltip title={stepThreeData.errorMessage}>
                      <ExclamationOutlined />
                    </Tooltip>
                  </div>
                  : ''
                }
              </Space>
            }
            actions={[
              <div className="ConfigFooter" id="FilterConfig" onClick={showModal}>
                <SettingOutlined />
                <span>  View configuration</span>
              </div>
            ]}
            bordered={false}
          >
            <div>
              <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                <div>
                  <p>No. of rows and columns <b>before</b> filtering:</p>
                  {stepThreeData && stepThreeData.beforeFiltering && stepThreeData.beforeFiltering.rowNumber && stepThreeData.beforeFiltering.columnNumber ?
                    <h3>{formatNumber(stepThreeData.beforeFiltering.rowNumber)} / {formatNumber(stepThreeData.beforeFiltering.columnNumber)} </h3>
                    : <h3>N/A</h3>}
                </div>

                <div>
                  <Tabs defaultActiveKey="1" size="small" centered={true}>
                    <TabPane tab="Rows" key="1">
                      {stepThreeData && stepThreeData.filterReport ?
                        <div>
                          <table className="rowsFiltering" style={{ marginTop: '-10px' }}>
                            <tbody>
                              {stepThreeData && stepThreeData.filterReport && (
                                <>
                                  <tr className="firstRow">
                                    <th>Category</th>
                                    <td># Rows</td>
                                  </tr>
                                  {stepThreeData.filterReport.noOfRowsWithValuesWithNotConsentAndReceived > 0 && (
                                    <tr className="filteredOut">
                                      <th>Explicit No Consent</th>
                                      <td>{formatNumber(stepThreeData.filterReport.noOfRowsWithValuesWithNotConsentAndReceived)}</td>
                                    </tr>
                                  )}
                                  {stepThreeData.filterReport.noOfRowsWithValuesWithNotConsentAndNotReceived > 0 && (
                                    <tr className="filteredOut">
                                      <th>Missing Consent</th>
                                      <td>{formatNumber(stepThreeData.filterReport.noOfRowsWithValuesWithNotConsentAndNotReceived)}</td>
                                    </tr>
                                  )}
                                  {stepThreeData.filterReport.noOfRowsWithBlankInput > 0 && (
                                    <tr className="outputRow">
                                      <th>Blank Input</th>
                                      <td>{formatNumber(stepThreeData.filterReport.noOfRowsWithBlankInput)}</td>
                                    </tr>
                                  )}
                                  {stepThreeData.filterReport.noOfRowsWithExplicitlyAllowedValues > 0 && (
                                    <tr className="outputRow">
                                      <th>
                                        <Tooltip title={"E.g. coded as '-1', '-2', etc. Specified in exportConfig's manuallyAddAllowedValues."}>
                                          <span style={{ marginRight: '10px' }}>
                                            <InfoCircleOutlined />
                                          </span>
                                        </Tooltip>
                                        Not Identified
                                      </th>
                                      <td>{formatNumber(stepThreeData.filterReport.noOfRowsWithExplicitlyAllowedValues)}</td>
                                    </tr>
                                  )}
                                  {stepThreeData.filterReport.noOfRowsWithValuesWithConsentAndReceived > 0 && (
                                    <tr className="lastRow">
                                      <th>Explicit Consent</th>
                                      <td>{formatNumber(stepThreeData.filterReport.noOfRowsWithValuesWithConsentAndReceived)}</td>
                                    </tr>
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                          <Space size="small">
                            {stepThreeData && stepThreeData.filterReport && (
                              <>
                                <Badge
                                  status='error'
                                  key='filteredOut'
                                  text='Filtered out'
                                />
                                <Badge
                                  status='success'
                                  key='outputRows'
                                  text='Output rows'
                                />
                              </>
                            )}
                          </Space>
                        </div>
                        :
                        <div>
                          {/*<p>Rows filtered out</p>*/}
                          <h3>N/A</h3>
                        </div>}
                    </TabPane>

                    <TabPane tab="Unique IDs" key="2">
                    {stepThreeData && stepThreeData.uniqueFilterReport ?
                      <div>
                        <table className="rowsFiltering" style={{ marginTop: '-10px' }}>
                          <tbody>
                            {stepThreeData && stepThreeData.uniqueFilterReport && (
                              <>
                                <tr className="firstRow">
                                  <th>Category</th>
                                  <td># Unique Ids</td>
                                </tr>
                                {stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithNotConsentAndReceived > 0 && (
                                  <tr className="filteredOut">
                                    <th>Explicit No Consent</th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithNotConsentAndReceived)}</td>
                                  </tr>
                                )}
                                {stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithNotConsentAndNotReceived > 0 && (
                                  <tr className="filteredOut">
                                    <th>Missing Consent</th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithNotConsentAndNotReceived)}</td>
                                  </tr>
                                )}
                                {stepThreeData.uniqueFilterReport.noOfRowsWithBlankInput > 0 && (
                                  <tr className="outputRow">
                                    <th>Blank Input</th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.noOfRowsWithBlankInput)}</td>
                                  </tr>
                                )}
                                {stepThreeData.uniqueFilterReport.noOfRowsWithExplicitlyAllowedValues > 0 && (
                                  <tr className="outputRow">
                                    <th>
                                      <Tooltip title={"E.g. coded as '-1', '-2', etc. Specified in exportConfig's manuallyAddAllowedValues."}>
                                        <span style={{ marginRight: '10px' }}>
                                          <InfoCircleOutlined />
                                        </span>
                                      </Tooltip>
                                      Not Identified
                                    </th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.noOfRowsWithExplicitlyAllowedValues)}</td>
                                  </tr>
                                )}
                                {stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithConsentAndReceived > 0 && (
                                  <tr className="outputRow">
                                    <th>Explicit Consent</th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.noOfRowsWithValuesWithConsentAndReceived)}</td>
                                  </tr>
                                )}
                                {stepThreeData.uniqueFilterReport.totalConsideredUniqueIds > 0 && (
                                  <tr className="lastRowUnique">
                                    <th>Total Considered Unique Ids </th>
                                    <td>{formatNumber(stepThreeData.uniqueFilterReport.totalConsideredUniqueIds)}</td>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                        <Space size="small">
                          {stepThreeData && stepThreeData.uniqueFilterReport && (
                            <>
                              <Badge
                                status='error'
                                key='filteredOut'
                                text='Filtered out'
                              />
                              <Badge
                                status='success'
                                key='outputRows'
                                text='Output rows'
                              />
                            </>
                          )}
                        </Space>
                      </div>
                      :
                      <div>
                        {/*<p>Rows filtered out</p>*/}
                        <h3>N/A</h3>
                      </div>}
                    </TabPane>
                  </Tabs>
                </div>

                <div>
                  <p>No. of rows and columns <b>after</b> filtering:</p>
                  {stepThreeData && stepThreeData.afterFiltering && stepThreeData.afterFiltering.rowNumber && stepThreeData.afterFiltering.columnNumber ?
                    <h3 className="positive">{formatNumber(stepThreeData.afterFiltering.rowNumber)} / {formatNumber(stepThreeData.afterFiltering.columnNumber)} </h3>
                    : <h3>N/A</h3>}
                </div>

                <div>
                  <p>
                    Dropped Columns:
                    <span style={{ marginLeft: '10px' }}>
                      <Tooltip title="Click to see which columns are deleted" >
                        <a id="droppedCols" onClick={showDroppedColsModal} >
                          <InfoCircleOutlined />
                        </a>
                      </Tooltip>
                    </span>
                  </p>
                  {stepThreeData && stepThreeData.droppedCols ?
                    <h3>{stepThreeData.droppedCols.length} </h3>
                    : <h3>N/A</h3>}
                </div>

                <div>
                  <p>
                    Auto-Dropped Columns:
                    <span style={{ marginLeft: '10px' }}>
                      <Tooltip title="Click to see which columns are automatically deleted because all cells were empty" >
                        <a id="autoDroppedCols" onClick={showDroppedColsModal} >
                          <InfoCircleOutlined />
                        </a>
                      </Tooltip>
                    </span>
                  </p>
                  {stepThreeData && stepThreeData.autoDroppedCols ?
                    <h3>{stepThreeData.autoDroppedCols.length} </h3>
                    : <h3>N/A</h3>}
                </div>

                <div>
                  <p>Runtime:</p>
                  {stepThreeData && stepThreeData.stepBeginTime && stepThreeData.stepEndTime ? (
                    <h3>{getRuntime(stepThreeData.stepBeginTime, stepThreeData.stepEndTime)}</h3>) : <h3>N/A</h3>}
                </div>


              </Space>
            </div>
          </Card>
        </Col>


        {/* STEP 4 (SPLIT) CARD */}
        <Col className="cardCol" key='step4Col' span={6}>
          <Card className="customCard" key='step4Card'
            style={{
              backgroundColor:
                stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'OK' ? ''
                  : stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'KO' ? '#ffccc7'
                    : stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'PENDING' ? '#ffe7ba'
                      : '#f0f0f0'
            }}
            headStyle={{
              backgroundColor:
                stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'OK' ? '#1677ff'
                  : stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'KO' ? '#ff4d4f'
                    : stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'PENDING' ? '#ffa940'
                      : '#bfbfbf',
              color: '#FFFFFF' // Set the text color of the head
            }}
            title={
              <Space>
                <SplitCellsOutlined />
                Split
                {stepFourData && stepFourData.stepStatus && stepFourData.stepStatus === 'KO' ?
                  <div style={{ height: '25px', width: '25px', backgroundColor: '#8080804D', borderRadius: '50%' }}>
                    <Tooltip title={stepFourData.errorMessage}>
                      <ExclamationOutlined />
                    </Tooltip>
                  </div>
                  : ''
                }
              </Space>
            }
            actions={[
              <div className="ConfigFooter" id="SplitConfig" onClick={showModal}>
                <SettingOutlined />
                <span>  View configuration</span>
              </div>
            ]}
            bordered={false}
          >
            <div>
              <Space direction="vertical" size="small" style={{ display: 'flex' }}>

                <div>
                  <p>No. of files created:</p>
                  {stepFourData && stepFourData.filesCreated ?
                    <h3 className="positive">{formatNumber(stepFourData.filesCreated)} </h3>
                    : <h3>N/A</h3>}
                </div>

                <div>
                  <p>No. of unique regions:</p>
                  {(stepFourData && stepFourData.splitReport && stepFourData.splitReport.regionNoOfUniqueValuesInDataset && stepFourData.splitReport.regionType) && stepFourData.splitReport.regionType === 'dynamic' ?
                    <h3>{formatNumber(stepFourData.splitReport.regionNoOfUniqueValuesInDataset.length)}</h3>
                    : (stepFourData && stepFourData.splitReport && stepFourData.splitReport.regionType) && stepFourData.splitReport.regionType === 'static' ?
                      <h3>1</h3>
                      : <h3>N/A</h3>
                  }
                </div>

                <div>
                  {stepFourData && stepFourData.splitReport && stepFourData.splitReport.uniqueValsPerSplittingField && stepFourData.splitReport.uniqueValsPerSplittingField.length > 0 ?

                    (stepFourData.splitReport.uniqueValsPerSplittingField).map(field =>
                      <div>
                        <p>Number of unique values "{Object.keys(field)}"</p>
                        <h3>{Object.values(field)}</h3>
                      </div>
                    ) : (
                      <div>
                        <p>Unique values per splitting:</p>
                        <h3>N/A</h3>
                      </div>
                    )}
                </div>

                <div>
                  <p>Runtime:</p>
                  {stepFourData && stepFourData.stepBeginTime && stepFourData.stepEndTime ? (
                    <h3>{getRuntime(stepFourData.stepBeginTime, stepFourData.stepEndTime)}</h3>) : <h3>N/A</h3>}
                </div>
              </Space>
            </div>
          </Card>
        </Col>

      </Row>

      {/* Config modal to be displayed once 'View Configuration' is clicked within a card*/}
      <ConfigInfoModal key={`modal-${props.rowKey}`} onClose={handleModalClose} isModalOpen={isModalOpen} modalId={modalId} currentConfig={currentConfig} />
      <ConfigInfoModal key={`modal-${props.rowKey}`} onClose={handleDroppedColsModalClose} isModalOpen={isDroppedColsModalOpen} modalId={droppedColsModalId} currentConfig={modalContent} />


    </div>
  )
}

export default CardsPanel;
import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../utilities/Firebase";
import _ from "lodash";

import { red, green, yellow } from "@ant-design/colors";

import {
  Card,
  List,
  Descriptions,
  Badge,
  Drawer,
  Button,
  Progress,
  Collapse,
  Spin,
  Table,
  Divider,
  Tag,
  Row,
  Col,
  Statistic,
  Typography
} from "antd";

const { Panel } = Collapse;
const { Title } = Typography;

const TenantFollowUpComponent = (props) => {
  const [tenants, setTenants] = useState({});
  const [consumableStats, setConsumableStats] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState({});
  const [loading, setLoading] = useState(true);
  const [aggregatedStats, setAggregatedStats] = useState({});
  






  const loadData = (scope) => {
    httpsCallable(
      functions,
      `getTenantsScope`
    )({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
      scope: scope
     
    }).then((r) => {

        console.log(r)

      let aggregatedSchools = 0;
      let aggregatedGroups = 0;
      
      let aggregatedGroupsPassedThreshold = 0;
      let aggregatedStudents = 0;
      let aggregatedConsentReceived = 0;
      let aggregatedReceivedConsented = 0;
      let aggregatedReceivedNoConsented = 0;
      let aggregatedMissingConsents = 0;



      let downloadedData = r.data;
      let consumableStatsArray = [];
      _.forEach(downloadedData, (value, key) => {
        let schoolObject = {};
        schoolObject.key = key;
        schoolObject.id = key;
        schoolObject.name = value.name || key;
        let totalGroups = 0;
        let totalGroupsPassedThreshold = 0;
        let totalStudents = 0;
        let totalConsentsReceived = 0;
        let totalStudentsConsented = 0;
        let totalStudentsNoConsented = 0;
       
        let students = value.students;
        let studentsByGroup = _.groupBy(students, "group");
        let statsByGroup = {};
        _.forEach(studentsByGroup, (group, key) => {
          if (key != '0 Demo' && key != 'Demo' && key.toLocaleLowerCase() != 'lecxit' && key.toLocaleLowerCase() != 'math') {
            totalGroups++;
            let newGroupObject = {};
            newGroupObject.totalStudents = group.length;
            totalStudents += group.length;
            newGroupObject.id = key;
            newGroupObject.key = key;
            let studentsConsentReceived = _.filter(group, {
              consentReceived: true,
            });
            newGroupObject.consentsReceived = studentsConsentReceived.length;
            totalConsentsReceived += newGroupObject.consentsReceived;
            let studentsReceivedNotConsented = _.filter(
              studentsConsentReceived,
              {
                noConsent: true,
              }
            );
            newGroupObject.studentsReceivedNotConsented = studentsReceivedNotConsented;
            newGroupObject.receivedNoConsented = studentsReceivedNotConsented.length;
            totalStudentsNoConsented += newGroupObject.receivedNoConsented;
            let studentsReceivedConsented = _.filter(studentsConsentReceived, {
              noConsent: false,
            });

            newGroupObject.receivedConsented = studentsReceivedConsented.length;
            totalStudentsConsented += newGroupObject.receivedConsented;
            newGroupObject.missingConsents =
              newGroupObject.totalStudents - newGroupObject.consentsReceived;
            newGroupObject.passedTreshold =
              newGroupObject.receivedConsented / newGroupObject.totalStudents >
                0.3
                ? true
                : false;
            if (newGroupObject.passedTreshold) totalGroupsPassedThreshold++;
            statsByGroup[key] = newGroupObject;

          }

        });

        schoolObject.totalStudentsConsented = totalStudentsConsented;
        schoolObject.totalConsentsReceived = totalConsentsReceived;
        schoolObject.totalStudentsNoConsented = totalStudentsNoConsented;
        schoolObject.missingConsents = totalStudents - totalConsentsReceived;
        schoolObject.totalGroups = totalGroups;
        schoolObject.totalGroupsPassedThreshold = totalGroupsPassedThreshold;
        schoolObject.groupsInfo = statsByGroup;
        schoolObject.totalStudents = totalStudents;
        consumableStatsArray.push(schoolObject);

        aggregatedSchools++;
        aggregatedGroups += totalGroups;
      
        aggregatedGroupsPassedThreshold += totalGroupsPassedThreshold;
        aggregatedStudents += totalStudents;
        aggregatedConsentReceived += totalConsentsReceived;
        aggregatedReceivedConsented += totalStudentsConsented;
        aggregatedReceivedNoConsented += totalStudentsNoConsented;
        aggregatedMissingConsents += totalStudents - totalConsentsReceived;



      });
      setLoading(false);
      console.log(consumableStatsArray);
      //let sorted = _.sortBy(consumableStatsArray),['totalConsentsReceived']
      setConsumableStats(_.sortBy(consumableStatsArray, ['totalConsentsReceived']));
      setAggregatedStats({
        aggregatedSchools: aggregatedSchools, aggregatedGroups: aggregatedGroups,
        aggregatedStudents: aggregatedStudents, aggregatedGroupsPassedThreshold: aggregatedGroupsPassedThreshold,
        aggregatedConsentReceived: aggregatedConsentReceived, aggregatedReceivedConsented: aggregatedReceivedConsented, aggregatedReceivedNoConsented: aggregatedReceivedNoConsented,
        aggregatedMissingConsents: aggregatedMissingConsents
      });
      console.log(aggregatedStudents, aggregatedConsentReceived, aggregatedReceivedConsented, aggregatedReceivedNoConsented, aggregatedMissingConsents);
    });
  };
  useEffect(() => {
    loadData(props.scope);
  }, []);

  const onClose = () => {
    setOpen(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const selectTenant = (nId) => {
    console.log("here", nId, consumableStats);
    let selectedTenantWithId = _.filter(consumableStats, { id: nId })[0];
    selectedTenantWithId.groupArray = _.sortBy(_.values(selectedTenantWithId.groupsInfo), ['id']);
    selectedTenantWithId.groupList = _.keys(selectedTenantWithId.groupsInfo);

    setSelectedTenant(selectedTenantWithId);

    console.log(selectedTenantWithId);

    showDrawer();
  };

  const printStudents = (nList) => {
    let studentList = nList.studentsReceivedNotConsented;
    return (<><Title level={5}>No consented students</Title>
      <Table size={'small'} dataSource={studentList}
        columns={[
          { title: 'Student name', dataIndex: 'name', key: 'name' },
        ]} /></>
    );

  }

  const printGroups = () => {
    let groupKeys = _.keys(selectedTenant.groupsInfo);
    _.forEach(groupKeys, (key) => {
      console.log(key, selectedTenant.groupsInfo[key]);
      return (
        <Descriptions.Item label="Class name" span={3}>
          {selectedTenant.groupsInfo[key].id}
        </Descriptions.Item>
      );
    });
  };

  return (
    <div>
      <Title level={4}>{props.scope==='and'?'Instituciones Sur':'Instituciones Catalunya'}</Title>
      <Spin spinning={loading}>
        {" "}
        {consumableStats.length > 0 && (
          <>
            <Divider orientation="left">General stats</Divider>
            <Row gutter={16} justify='center'>
              <Col span={5}>
                <Statistic title="Total schools" value={aggregatedStats.aggregatedSchools} />
              </Col>
              <Col span={5}>
                <Statistic title="Total groups" value={aggregatedStats.aggregatedGroups} />
              </Col>
              
             

              <Col span={5}>
                <Statistic title="Total students" value={aggregatedStats.aggregatedStudents} />
              </Col>

            </Row>
            <Row gutter={16} justify='center'>

           
              <Col span={5}>
                <Statistic title="Total consents received" value={aggregatedStats.aggregatedConsentReceived} />
              </Col>

              <Col span={5}>
                <Statistic title="Total consents received consented" value={aggregatedStats.aggregatedReceivedConsented} />
              </Col>
              <Col span={5}>
                <Statistic title="Total consents received NO consented" value={aggregatedStats.aggregatedReceivedNoConsented} />
              </Col>
              <Col span={5}>
                <Statistic title="Total missing consents" value={aggregatedStats.aggregatedMissingConsents} />
              </Col>
            </Row>

           


            <Divider orientation="left">School list</Divider>
            <List
              size="small"
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
              dataSource={consumableStats}

              renderItem={(item) => (
                <List.Item>
                  <Card title={item.name}>
                    <Descriptions size='small' bordered column={{
        xxl: 4,
        xl: 3,
        lg: 3,
        md: 3,
        sm: 2,
        xs: 1,
      }}>
                      <Descriptions.Item label="Center Code" span={3}>
                        {item.key}
                      </Descriptions.Item>

                    
                      <Descriptions.Item label="Number of groups" span={3}>
                        {item.totalGroups}
                      </Descriptions.Item>
                      <Descriptions.Item label="Number of students" span={3}>
                        {item.totalStudents}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total consents received" span={3}>
                        {item.totalConsentsReceived + ' (' + (item.totalConsentsReceived * 100 / item.totalStudents).toFixed(2) + '%)'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total consents received & consented" span={3}>
                        {item.totalStudentsConsented + ' (' + (item.totalStudentsConsented * 100 / item.totalStudents).toFixed(2) + '%)'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total consents received & NO consented" span={3}>
                        {item.totalStudentsNoConsented + ' (' + (item.totalStudentsNoConsented * 100 / item.totalStudents).toFixed(2) + '%)'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total missining consents" span={3}>
                        {item.missingConsents + ' (' + (item.missingConsents * 100 / item.totalStudents).toFixed(2) + '%)'}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Percentage of consents received"
                        span={3}
                      >
                        <Progress
                          percent={(
                            (item.totalConsentsReceived * 100) /
                            item.totalStudents
                          ).toFixed(2)}
                          steps={5}
                          strokeColor={[
                            red[6],
                            red[4],
                            yellow[6],
                            yellow[4],
                            green[6],
                          ]}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Percentage of consented students"
                        span={3}
                      >
                        <Progress
                          percent={(
                            (item.totalStudentsConsented * 100) /
                            item.totalStudents
                          ).toFixed(2)}
                          steps={5}
                          strokeColor={[
                            red[6],
                            red[4],
                            yellow[6],
                            yellow[4],
                            green[6],
                          ]}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                    <Button onClick={() => selectTenant(item.key)} type="primary">
                      View details
                    </Button>
                  </Card>
                </List.Item>
              )}
            /></>

        )}
        {selectedTenant != null && (
          <Drawer
            title={selectedTenant.name}
            placement="right"
            onClose={onClose}
            open={open}
            size={"large"}
          >
            <Descriptions bordered size="small">
              <Descriptions.Item label="Center Code" span={3}>
                {selectedTenant.key}
              </Descriptions.Item>
              <Descriptions.Item label="Number of students" span={3}>
                {selectedTenant.totalStudents}
              </Descriptions.Item>
              
              <Descriptions.Item label="Number of groups" span={3}>
                {selectedTenant.totalGroups}
              </Descriptions.Item>
              <Descriptions.Item label="Total consents received" span={3}>
                {selectedTenant.totalConsentsReceived + ' (' + (selectedTenant.totalConsentsReceived * 100 / selectedTenant.totalStudents).toFixed(2) + '%)'}
              </Descriptions.Item>
              <Descriptions.Item label="Total consents received & consented" span={3}>
                {selectedTenant.totalStudentsConsented + ' (' + (selectedTenant.totalStudentsConsented * 100 / selectedTenant.totalStudents).toFixed(2) + '%)'}
              </Descriptions.Item>
              <Descriptions.Item label="Total consents received & NO consented" span={3}>
                {selectedTenant.totalStudentsNoConsented + ' (' + (selectedTenant.totalStudentsNoConsented * 100 / selectedTenant.totalStudents).toFixed(2) + '%)'}
              </Descriptions.Item>
              <Descriptions.Item label="Total missining consents" span={3}>
                {selectedTenant.missingConsents + ' (' + (selectedTenant.missingConsents * 100 / selectedTenant.totalStudents).toFixed(2) + '%)'}
              </Descriptions.Item>

            </Descriptions>
            <Divider orientation="left">Details by group</Divider>
            <Table size={'small'} dataSource={selectedTenant.groupArray}

              expandable={{
                expandedRowRender: (record) => (<>
                  {printStudents(record)}
                </>
                ),
                rowExpandable: record => record.receivedNoConsented > 0,
              }}
              columns={[
                { title: 'Group name', dataIndex: 'id', key: 'id' },

                { title: 'Total students', dataIndex: 'totalStudents', key: 'totalStudents' },
                { title: 'Total received', dataIndex: 'consentsReceived', key: 'consentsReceived' },
                { title: 'Received & consented', dataIndex: 'receivedConsented', key: 'receivedConsented' },
                { title: 'Received & NO consented', dataIndex: 'receivedNoConsented', key: 'receivedNoConsented' },
                { title: 'Missing consents', dataIndex: 'missingConsents', key: 'missingConsents' }]} />

            

          </Drawer>
        )}
      </Spin>
    </div>
  );
};

export default TenantFollowUpComponent;

import { Modal } from 'antd';
import React, { useEffect, useState } from "react";


const ConfigInfoModal = (props) => {
  const [modalContent, setModalContent] = useState('');
  let currentConfig = props.currentConfig;

  //if there is a payload, remove the token from it
  let dataSetPayload;
  if (props.currentConfig && props.currentConfig.payload) {
    let payloadObj = props.currentConfig.payload;
    dataSetPayload = { ...payloadObj };
    delete dataSetPayload.data.token;
    // console.log('updated dataSetPayload', dataSetPayload);
  }

  //update content of the modal if modal id changes
  useEffect(() => {
    updateContent();
  }, [props.modalId])



  //update the content of the Modal (configuration pop up window) depending on which step´s configuration user clicks on
  const updateContent = () => {
    let content;
    if (currentConfig) {
      let configChange;
      if (props.currentConfig && props.currentConfig.versioning && props.currentConfig.versioning.configLastChanged && props.currentConfig.versioning.configLastChanged.change) {
        configChange = props.currentConfig.versioning.configLastChanged.change;
      }

      //update the info for 'Download' configuration
      if (props.modalId === 'DownloadConfig') {
        !currentConfig.id && !currentConfig.endpoint && !currentConfig.paths && !currentConfig.paths.sourcePath && !dataSetPayload ? (
          content = (<div><p>No information available</p></div>)
        ) : (
          content = (
            <div>
              <h5>Raw file info</h5>
              <ul>
                {currentConfig.id ? (
                  <li><b>File:</b>  {currentConfig.id}</li>
                ) : ''}
                {currentConfig.endpoint ? (
                  <li><b>Endpoint:</b>  {currentConfig.endpoint}</li>
                ) : ''}
                {currentConfig.paths && currentConfig.paths.sourcePath ? (
                  <li><b>Source Path:</b>  {currentConfig.paths.sourcePath}</li>
                ) : ''}
                {dataSetPayload && (Object.keys(dataSetPayload.data).length > 0) ? (
                  <li><b>Payload:</b><pre>{JSON.stringify(dataSetPayload, null, 2)}</pre></li>
                ) : ''}
                {currentConfig.versioning && currentConfig.versioning.configLastChanged && currentConfig.versioning.configLastChanged.date ? (
                  <li><b>exportConfig last changed on:</b>  {currentConfig.versioning.configLastChanged.date}</li>
                ) : ''}
                {configChange ? (
                  <li><b>exportConfig changed as such:</b><pre>{JSON.stringify(configChange, null, 2)}</pre></li>
                ) : ''}
              </ul>
            </div>
          )
        );

        //update the info for 'Encrypt' configuration
      } else if (props.modalId === 'EncryptConfig') {
        currentConfig.encryptionFields ? (
          content = (
            <div>
              <h5>Encryption info</h5>
              <ul>
                {currentConfig.encryptionKey ? (
                  <li><b>Encryption key:</b>  {currentConfig.encryptionKey}</li>
                ) : ''}
                <li>
                  <b>Encryption fields:</b>
                  <ul>
                    {currentConfig.encryptionFields.map((item) => (
                      <li key={item.field}>{item.field}</li>))}
                  </ul>
                </li>
              </ul>
            </div>
          )
        ) : (
          content = (<div><p>No information available</p></div>)
        )
        //update the info for 'Filter' configuration
      } else if (props.modalId === 'FilterConfig') {
        !currentConfig.filterFields && !currentConfig.dropCols ? (
          content = (<div><p>No information available</p></div>)
        ) : (
          content = (
            <div>
              {currentConfig.filterFields ? (
                <>
                  <h5>Filter fields:</h5>
                  <ul>
                    {(currentConfig.filterFields).map((item) => Object.entries(item).map(([key, value]) => (
                      <div>
                        <li>{key}</li>
                        <ul>
                          {Object.entries(value).map(([key2, value2]) => (
                            <div>
                              <li key={key2}>{key2}</li>
                              {Array.isArray(value2) ? (
                                <ul>
                                  {value2.map((item) => (
                                    <li key={item}>{item}</li>))}
                                </ul>
                              )
                                : (
                                  <ul>
                                    <li>{value2.toString()}</li>
                                  </ul>
                                )}
                            </div>
                          ))}
                        </ul>
                      </div>
                    )))}
                  </ul>
                </>
              ) : ''}

              {currentConfig.dropCols ? (
                <>
                  <h5>Columns configured for deletion:</h5>
                  {(currentConfig.dropCols).map((item) => (
                    <div>
                      <ul>
                        <li>{item}</li>
                      </ul>
                    </div>))}
                </>
              ) : ''}
            </div>
          )
        )
        //update the info for 'Split' configuration
      } else if (props.modalId === 'SplitConfig') {
        currentConfig.sortingFields ? (
          content = (
            <div>
              <h5>Sorting fields:</h5>
              {(currentConfig.sortingFields).map((item) => (
                <div>
                  <p>Field</p>
                  <ul>
                    {Object.entries(item).map(([key, value]) => (
                      <li>{key}: {value}</li>
                    ))}
                  </ul>
                </div>))}

            </div>
          )
        ) : (
          content = (<div><p>No information available</p></div>)
        );
        //handle error (in case no proper config id is sent), show 'No information available'

      } else if (props.modalId === 'droppedCols') {
        currentConfig.droppedCols ? content = (
          <div>
            <h5>These are the columns from the configure file, that were actually found and dropped during the processing of the dataset.</h5>
            {(currentConfig.droppedCols).map((item) => (
              <div>
                <ul>
                  <li>{item}</li>
                </ul>
              </div>))}
          </div>

        ) : (
          content = (<div><p>No information available</p></div>)
        );

      } else if (props.modalId === 'autoDroppedCols') {
        currentConfig.autoDroppedCols ? content = (
          <div>
            <h5>These are the columns where all celsl in the column were found to be empty and hence the column was deleted automatically.</h5>
            {(currentConfig.autoDroppedCols).map((item) => (
              <div>
                <ul>
                  <li>{item}</li>
                </ul>
              </div>))}
          </div>

        ) : (
          content = (<div><p>No information available</p></div>)
        );

      } else if (props.modalId === 'RawColNames') {
        currentConfig.rawColumnNames ? content = (
          <div>
            <h5>These are the columns that were loaded in the beginning of the processing pipeline.</h5>
            {(currentConfig.rawColumnNames).map((item) => (
              <div>
                <ul>
                  <li>{item}</li>
                </ul>
              </div>))}
          </div>

        ) : (
          content = (<div><p>No information available</p></div>)
        );

      } else if (props.modalId === 'unmatchedUniqueIDs') {
        currentConfig.encryptReport.uniqueUnencryptedUniqueIds ? content = (
          <div>
            <h5>These are the IDs that were not encrypted because they couldn't be found in the database.</h5>
            <ul>
              {currentConfig.encryptReport.uniqueUnencryptedUniqueIds
                .map(item => String(item))
                .sort((a, b) => a.length - b.length)
                .map(item => (
                  <li key={item}>{item}</li>
                ))}
            </ul>
          </div>

        ) : (
          content = (<div><p>No information available</p></div>)
        );

      } else {
        content = (
          <div>
            <p>No information available</p>
          </div>);
      }

      //if no configuration information is available - show 'No information available'
    } else {
      content = (
        <div>
          <p>No information available</p>
        </div>);
    }
    //set the state of the Modal content
    setModalContent(content)
  }


  //return the configuration information in the pop up (Modal)
  return (
    <>
      {/* { currentConfig ? console.log('currentConfig', props.currentConfig) : '' } */}
      <Modal
        title={
          props.modalId === 'droppedCols'
            ? 'Dropped Columns'
            : props.modalId === 'RawColNames'
              ? 'Raw Columns'
              : 'Configuration'
        }
        open={props.isModalOpen}
        footer={null}
        mask={true}
        maskClosable={true}
        onCancel={props.onClose}
        closable={true}
      >
        {modalContent}
      </Modal>
    </>
  );
};


export default ConfigInfoModal;

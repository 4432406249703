import { makeAutoObservable } from "mobx";

import { functions } from "../../utilities/Firebase";
import { httpsCallable } from "firebase/functions";

const ACCESS_TOKEN = "AC335ED6B3FE8C936C7F6D6AD3D51";

class ImportStudentsStore {
  uploadedSpreadsheetData = null;
  steps = [
    {
      title: "step one",
      content: "Upload",
    },
    {
      title: "step two",
      content: "Review",
    },
    {
      title: "step three",
      content: "Results",
    },
    {
      title: "step four",
      content: "Finalize",
    },
  ];
  unmatchedFields = false;
  keysAvailable = ["id", "idGroup", "firstName", "lastName", "group", "level", "line", "listNumber", "idMonkStudent", "idMonkGroup", "idTea", "consentReceived", "noConsent", "noConsentLong", "idMonk"];
  expectedKeys = ["id", "idGroup", "firstName", "lastName", "group", "level", "line", "listNumber", "idMonkStudent", "idMonkGroup", "idTea", "consentReceived", "noConsent", "noConsentLong", "idMonk"];
  isLoading = false;
  status = "success";
  message = "success message";
  errorMessages = [];
  subjectsToCreate = null;
  selectedShool = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedShool(id) {
    this.selectedShool = id;
  }

  setUploadedSpreadsheetData = (newData) => {
    this.uploadedSpreadsheetData = newData;
  };

  setSubjectsToCreate(newSubjects) {
    this.subjectsToCreate = newSubjects;
  }

  setUnmatchedFields(newValue) {
    this.unmatchedFields = newValue;
  }

  setMessage = (newMessage) => {
    this.message = newMessage;
  };

  async createUsersFromSpreadsheet() {
    try {
      await httpsCallable(
        functions,
        "createStudents"
      )({
        token: ACCESS_TOKEN,
        tenant: this.selectedShool,
        students: this.subjectsToCreate,
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ImportStudentsStore();

import React, { useEffect, useState } from "react";
import { PageContainer, ProCard, ProConfigProvider, ProFormSelect, QueryFilter, ProList } from "@ant-design/pro-components";
import axios, * as others from 'axios';
import { Select, Space, Button, List, Card, Descriptions, Skeleton, Typography, Collapse, Anchor, Row, Col } from "antd";
import _, { forEach } from 'lodash';
import { httpsCallable } from "firebase/functions";
import { functions } from "../utilities/Firebase";
import { Pie, Bar, Line } from '@ant-design/plots';
import { DecompositionTreeGraph } from '@ant-design/graphs';

const { Title } = Typography;
const { Panel } = Collapse;

const SurveyCheck = () => {
    const [loading, setLoading] = useState(true);
    const [availableSurveys, setAvailableSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState('');
    const [surveyDefinition, setSurveyDefinition] = useState({});
    const [identificationField, setIdentificationField] = useState('');
    const [surveyStats, setSurveyStats] = useState({});
    const [loadingStats, setLoadingStats] = useState(false);

    const apiToken = 'HPSVUGrwKbZOLlTxsNxadFEVWr3IG9CFmwJdUnbf';
    const apiEndpoint = 'https://fra1.qualtrics.com/API/v3/';

    const getData = async (url, headers) => {
        let result = { status: 400, data: {} }
        try {
            const response = await axios.get(url, headers);
            result.status = 200;
            result.data = response.data;
        } catch (error) {
            result.statusCode = error.status;
            result.data = error;
        }
        return result;
    }
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        //3. Get all surveys and check if there is one that exists with the given name or id.
        let query = apiEndpoint + 'surveys';
        let results = await getData(query, { headers: { 'X-API-TOKEN': apiToken } });
        if (results.status === 200) {
            let allSurveys = results.data.result.elements;
            setAvailableSurveys(_.sortBy(_.filter(allSurveys, function(o){return ((o['name']).startsWith('lec_') || (o['name']).startsWith('mat_'))}), 'name'));
            setLoading(false);
        }

    }

    const onSurveySelect = async (selected) => {
        let query = apiEndpoint + 'surveys/' + selected;
        let results = await getData(query, { headers: { 'X-API-TOKEN': apiToken } });
        setSelectedSurvey(selected);
        setSurveyDefinition(results.data.result);

    }

    const onSelectedIdentificationField = (selected) => {
        setIdentificationField(selected);
    }

    const downloadStatistics = () => {
        setSurveyStats({})
        setLoadingStats(true);
        httpsCallable(
            functions,
            `getSurveyResponsesStats`
        )({
            token: "AC335ED6B3FE8C936C7F6D6AD3D51",
            surveyId: selectedSurvey,
            identifyField: identificationField
        }).then((r) => {
            console.log('loaded');
            let processedData = _.forEach((r.data.statistics), (object, key) => ({ ...object, key }));
            r.data.statistics = processedData;
            console.log(r.data);
            setSurveyStats(r.data);
            setLoadingStats(false);
        });
    }


    const config = {
        appendPadding: 10,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ value, percent }) => (value > 0 ? `${value} (${(percent * 100).toFixed(0)}%)` : ''),
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    const configQuintiles = {

        xField: 'value',
        yField: 'status',
        seriesField: 'quintile',
        isStack: true,
        label: {
            position: 'middle',
            content: (item) => {
                return item.value;
            },
            style: {
                fill: '#fff',
            },
        },
    };

    const configLine = {
        xField: 'progress',
        yField: 'value',
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    };

    const getSortedKeys = (keys) => {
        const sortedKeys = _.sortBy(keys, (key) => {
            if (key === "stats") {
                return 0; // Place "stats" at the beginning
            } else if (key === "undefined") {
                return Infinity; // Place "undefined" at the end
            } else if (_.isNumber(_.toNumber(key))) {
                return _.toNumber(key); // Sort numeric keys
            } else {
                return 1; // Sort non-numeric keys in between
            }
        });
        return sortedKeys;
    }



    return (
        <div
            style={{
                background: "#F5F7FA",
            }}
        >

            <PageContainer
                header={{
                    title: "Survey check",
                    ghost: true,
                }}
            >
                <Row>
                    <Col span={22}>
                        <ProCard loading={loading} direction="column" ghost gutter={[0, 16]}>
                            <ProCard style={{ minHeight: 200 }}>
                                <Space direction="vertical">
                                    <Space direction="horizontal" size='large' width='100%'>
                                        Select survey
                                        <Select size="middle" options={availableSurveys} fieldNames={{ 'label': 'name', 'value': 'id' }} style={{ width: '20vw' }} onChange={(selected) => onSurveySelect(selected)} />
                                        {selectedSurvey !== '' && (
                                            <>
                                                Select identification field
                                                <Select size="middle" options={surveyDefinition.embeddedData} fieldNames={{ 'label': 'name', 'value': 'name' }} style={{ width: '20vw' }} onChange={(selected) => onSelectedIdentificationField(selected)} />
                                                <Button onClick={() => downloadStatistics()}>Get statistics</Button>
                                            </>
                                        )}

                                    </Space>

                                    {loadingStats && (<Skeleton loading />)}


                                    {!_.isEmpty(surveyStats) && (<>

                                        <Card title='General statistics' id='stats'>

                                            <Descriptions bordered size="small">
                                                <Descriptions.Item label="Total responses" span={3}>
                                                    {surveyStats.statistics['stats']['totalNumberOfResponses']}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total number of schools" span={3}>
                                                    {surveyStats.statistics['stats']['numberOfSchools']}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total number of groups" span={3}>
                                                    {surveyStats.statistics['stats']['numberOfGroups']}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Title level={5}>Sorted by "finished" property</Title>
                                            <Pie {...config} data={[
                                                {
                                                    type: 'Completed',
                                                    value: surveyStats.statistics['stats']['totalNumberOfCompletedRespones'],
                                                },
                                                {
                                                    type: 'Non Completed',
                                                    value: surveyStats.statistics['stats']['totalNumberOfNonCompletedRespones'],
                                                },
                                            ]} />
                                            {(surveyStats.statistics['stats']['totalNumberOfNonCompletedRespones'] !== 0) && (<>
                                                <Title level={5}>Progress frequency</Title>
                                                <Line {...configLine} data={_.map(_.sortBy(surveyStats.statistics['stats']['progressTab'], ['progress']), (obj) => ({ ...obj, progress: String(obj.progress) }))} />

                                            </>)}



                                            {(identificationField !== '') && (
                                                <>
                                                    <Descriptions bordered size="small">
                                                        <Descriptions.Item label="Total empty id field" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdEmpty']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total -1 id field" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdMinus1']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total -2 id field" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdMinus2']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total -2 id field with name indicated" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdMinus2WithName']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total -2 id field with NO name indicated" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdMinus2WithoutName']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total with id field filled" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdValid']}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Total with unique id field filled" span={3}>
                                                            {surveyStats.statistics['stats']['totalIdValidUnique']}
                                                        </Descriptions.Item>

                                                    </Descriptions>

                                                </>
                                            )}

                                        </Card>



                                        <List
                                            size="small"
                                            grid={{
                                                gutter: 16,
                                                xs: 1,
                                                sm: 1,
                                                md: 1,
                                                lg: 1,
                                                xl: 1,
                                                xxl: 1,
                                            }}
                                            dataSource={_.remove(getSortedKeys(_.keys(surveyStats.statistics)), function (n) { return n !== 'stats' })}
                                            renderItem={(key) => (
                                                <List.Item id={key}>
                                                    <Card title={key}>
                                                        <List size="small" dataSource={_.keys(surveyStats.statistics[key])} renderItem={(groupKey) => (

                                                            <Card title={groupKey === 'stats' ? 'School statistics' : groupKey}>

                                                                <Descriptions bordered size="small">
                                                                    <Descriptions.Item label="Total responses" span={3}>
                                                                        {surveyStats.statistics[key][groupKey]['totalNumberOfResponses']}
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Title level={5}>Sorted by "finished" property</Title>
                                                                <Pie {...config} data={[
                                                                    {
                                                                        type: 'Completed',
                                                                        value: surveyStats.statistics[key][groupKey]['totalNumberOfCompletedRespones'],
                                                                    },
                                                                    {
                                                                        type: 'Non Completed',
                                                                        value: surveyStats.statistics[key][groupKey]['totalNumberOfNonCompletedRespones'],
                                                                    },

                                                                ]} />

                                                                {(surveyStats.statistics[key][groupKey]['totalNumberOfNonCompletedRespones'] !== 0) && (<>
                                                                    <Title level={5}>Progress frequency</Title>
                                                                    <Line {...configLine} data={_.map(_.sortBy(surveyStats.statistics[key][groupKey]['progressTab'], ['progress']), (obj) => ({ ...obj, progress: String(obj.progress) }))} />
                                                                </>)}

                                                                {(identificationField !== '') && (
                                                                    <>
                                                                        <Descriptions bordered size="small">
                                                                            <Descriptions.Item label="Total empty id field" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdEmpty']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total -1 id field" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdMinus1']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total -2 id field" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdMinus2']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total -2 id field with name indicated" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdMinus2WithName']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total -2 id field with NO name indicated" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdMinus2WithoutName']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total with id field filled" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdValid']}
                                                                            </Descriptions.Item>
                                                                            <Descriptions.Item label="Total with unique id field filled" span={3}>
                                                                                {surveyStats.statistics[key][groupKey]['totalIdValidUnique']}
                                                                            </Descriptions.Item>

                                                                        </Descriptions>

                                                                    </>

                                                                )}


                                                            </Card>
                                                        )}></List>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        ></List>
                                    </>)}
                                </Space>
                            </ProCard>
                        </ProCard>
                    </Col>
                    <Col span={2}>
                        {!_.isEmpty(surveyStats) && (<Anchor affix={true} offsetTop={100} items={_.map(getSortedKeys(_.keys(surveyStats.statistics)), (value) => { return _.zipObject(['key', 'href', 'title'], [value, "#" + value, value]); })} />)}
                    </Col>
                </Row>


            </PageContainer>


        </div>
    );

}

export default SurveyCheck;
import { PageContainer, ProCard, ProConfigProvider } from "@ant-design/pro-components";
import { Button, Modal, Form, Input } from "antd";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { functions } from "../utilities/Firebase";
import ImportStudents from "./Components/ImportStudents";
import SchoolsTable from "./SchoolsTable";
import StudentsTable from "./StudentsTable";
import ImportStudentsStore from "./Components/ImportStudentsStore";
import en from 'antd/locale/en_US';

const Manager = (props) => {
  
  const [tenants, setTenants] = useState(null);
  const [tab, setTab] = useState(1);
  const [isImportDrawerOpen, setIsImportDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRefreshOpen,setIsModalRefreshOpen] = useState(false);

  const [tabList, setTabList] = useState([
    {
      tab: "Todas las instituciones",
      key: 1,
      closable: false,
    },
  ]);
  const [selectedSchool, setSelectedSchool] = useState(-1);

  const loadData = () => {
    httpsCallable(
      functions,
      `getTenants`
    )({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
    }).then((r) => {
      console.log('loaded');
      setTenants(r.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);


  const showRefreshModal = () => {
    setIsModalRefreshOpen(true);
  }

  const hideRefreshModal = () => {
    setIsModalRefreshOpen(false);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (values) => {
    
    setIsModalOpen(false);
    sendDataFirebase(values);
    showRefreshModal();
    cleanSchoolForm();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    cleanSchoolForm();
  };

  const cleanSchoolForm = () => {
    form.resetFields();
  };

  const sendDataFirebase = (values) => {

    httpsCallable(functions, `createTenant`)({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
      name: values.name,
      id: values.id
  })
  .then((r) => {
      if(r.data === 'tenantCreated'){
        console.log('success');
        loadData();
      }
  })
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const selectSchool = (id) => {
    let currentTabList = [
      {
        tab: "Todas las instituciones",
        key: 1,
        closable: false,
      },
      {
        tab: tenants[id].name,
        key: id,
        closable: true,
      },
    ];

    setTabList(currentTabList);
    setTab(id);
    setSelectedSchool(id);
    ImportStudentsStore.setSelectedShool(id);
  };
  const unselectSchool = (id) => {
    let currentTabList = [
      {
        tab: "Todas las instituciones",
        key: 1,
        closable: false,
      },
    ];
    setTabList(currentTabList);
    setTab(1);
    setSelectedSchool(-1);
  };
  const addStudents = () => setIsImportDrawerOpen(true);
  const closeImportDrawer = () => setIsImportDrawerOpen(false);
  const addSchoolButton = () => {
    showModal();
  };

  const changeName = (id) => {};
  const [form] = Form.useForm();

  return (
    <div
      style={{
        background: "#F5F7FA",
      }}
    >
      
      <PageContainer
        header={{
          title: "Gestor de Instituciones",
          ghost: true,

          extra: [
            <Button key="1" type="primary" onClick={() => addSchoolButton()}>
              Agregar Institución
            </Button>,
          ],
        }}
        tabActiveKey={tab}
        tabList={tabList}
        tabProps={{
          type: "editable-card",
          hideAdd: true,
          onEdit: (e, action) => (action === "remove" ? unselectSchool(e) : ""),
        }}
      >
        <ProCard direction="column" ghost gutter={[0, 16]}>
          <ProCard style={{ minHeight: 200 }}>
            {tab === 1 ? (
              <SchoolsTable tenants={tenants} selectSchool={selectSchool} />
            ) : (
              <StudentsTable
                tenants={tenants}
                selectedSchool={selectedSchool}
                addStudents={addStudents}
                
              />
            )}
          </ProCard>
        </ProCard>
      </PageContainer>
      <ImportStudents
        isVisible={isImportDrawerOpen}
        onClose={closeImportDrawer}
        showRefreshModal={showRefreshModal}
      />
      <Modal
        title="Información de institución"
        open={isModalOpen}
       
        footer={null}
      >
        <Form form={form} name="schoolForm" onFinish={handleOk}>
          <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="id" label="ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
          Guardar
        </Button>
        <Button onClick={() => handleCancel()} >
          Cancelar
        </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="Refrescar" open={modalRefreshOpen} onCancel={hideRefreshModal} footer={null}>
        Para ver los cambios debes refrescar la pantalla.
      </Modal>
      
    </div>
  );
};

export default Manager;

import React, { useState, useEffect } from 'react';
import { Select, Button, Row, Col, Input, InputNumber } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const SplittingDynamicInputs = ({ onInputChange, updateData }) => {
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (Array.isArray(updateData) && updateData.length > 0) {
      setInputs(updateData);
    } else {
      setInputs([{ id: 1, fieldName: '', type: '', order: 1 }]);
    }
  }, [updateData]);

  const handleInputChange = (index, key, value) => {
    const updatedInputs = inputs.map((input, i) =>
      i === index ? { ...input, [key]: value } : input
    );
    setInputs(updatedInputs);
    onInputChange(updatedInputs); // Pass the updated inputs to the parent component
  };

  const handleAddInput = () => {
    const newInput = {
      id: inputs.length + 1,
      fieldName: '',
      type: '',
      order: inputs.length + 1,
    };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = inputs.filter((input, i) => i !== index);
    setInputs(updatedInputs);

    // Reassign the id values of the remaining inputs
    const renumberedInputs = updatedInputs.map((input, i) => ({
      ...input,
      id: i + 1,
    }));
    setInputs(renumberedInputs);
    onInputChange(renumberedInputs); // Update the parent component with the updated inputs
  };

  return (
    <div>
      <Button onClick={handleAddInput}> <PlusOutlined /> </Button>
      {inputs.map((input, index) => (
        <div key={index}>
          <Row gutter={16}>
            <Col span={6}>
              <Input
                placeholder="Field Name"
                onBlur={(event) =>
                  handleInputChange(index, 'fieldName', event.target.value)
                }
                value={input.fieldName}
                onChange={(event) =>
                  handleInputChange(index, 'fieldName', event.target.value)
                }
              />
            </Col>
            <Col span={6}>
              <Select
                onChange={(value) =>
                  handleInputChange(index, 'type', value)
                }
                style={{ width: '100%' }}
                placeholder="Folder or File"
                value={input.type}
              >
                <Option value="dir">Folder</Option>
                <Option value="file">File</Option>
              </Select>
            </Col>
            <Col span={6}>
              <InputNumber
                defaultValue={input.order}
                onChange={(value) =>
                  handleInputChange(index, 'order', value)
                }
              />
            </Col>
            {inputs.length > 1 && (
              <Col span={2}>
                <Button onClick={() => handleRemoveInput(index)}>
                  <DeleteOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default SplittingDynamicInputs;

import JSZip from 'jszip';
import _ from "lodash";

export function convertToCSV(data) {
    const header = Object.keys(data[0]).join(',');
    const csv = data.map((row) =>
        Object.values(row)
            .map((value) =>
                typeof value === 'string'
                    ? `"${value.replace(/"/g, '""')}"`
                    : value
            )
            .join(',')
    );
    return [header, ...csv].join('\n');
}

// Function to download a CSV file
export function downloadCSV(data, filename) {
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
    const link = document.createElement('a');
    link.href = csvContent;
    link.download = filename;
    link.click();
}

// Example usage to download questions CSV
export function downloadQuestionsCSV(datasetName, dataSetInformation) {
    const questionsData = dataSetInformation.allQuestions;
    const csvData = convertToCSV(questionsData);
    const filename = `${datasetName}_questions.csv`;
    downloadCSV(csvData, filename);
}

// Example usage to download options CSV
export function downloadOptionsCSV(datasetName, dataSetInformation) {
    const optionsData = dataSetInformation.allOptions;
    const csvData = convertToCSV(optionsData);
    const filename = `${datasetName}_options.csv`;
    downloadCSV(csvData, filename);
}

export function downloadInstructionsTxt(datasetName, dataSetInstructions) {
    const blob = new Blob([dataSetInstructions], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${datasetName}_stata_instructions.txt`;
    link.click();
}

export function downloadAllQuestionsAsCSV(datasets) {
    // Combine all questions with dataset names
    const combinedData = datasets.reduce((accumulator, item) => {
        const datasetName = item.name;
        const surveyId = item.surveyId;
        const questionsData = item.dataSetInformation.allQuestions.map(question => ({
            ...question,
            dataset: datasetName, // Add dataset name as a column
            surveyId: surveyId // Add dataset name as a column
        }));

        return accumulator.concat(questionsData);
    }, []);

    // Convert combined data to CSV
    const questionsCSVData = convertToCSV(combinedData);

    // Create a Blob containing the CSV data
    const blob = new Blob([questionsCSVData], { type: 'text/csv' });

    // Create a link for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_questions.csv';
    link.click();
}

export function downloadAllOptionsAsCSV(datasets) {
    // Combine all options with dataset names
    const combinedData = datasets.reduce((accumulator, item) => {
        const datasetName = item.name;
        const surveyId = item.surveyId;
        const optionsData = item.dataSetInformation.allOptions.map(option => ({
            ...option,
            dataset: datasetName,
            surveyId: surveyId // Add dataset name as a column
        }));

        return accumulator.concat(optionsData);
    }, []);

    // Convert combined data to CSV
    const optionsCSVData = convertToCSV(combinedData);

    // Create a Blob containing the CSV data
    const blob = new Blob([optionsCSVData], { type: 'text/csv' });

    // Create a link for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'all_options.csv';
    link.click();
}

export function downloadAllCSVsAsZip(datasets) {
    const zip = new JSZip();

    datasets.forEach((item) => {
        const datasetName = item.name;
        const questionsData = item.dataSetInformation.allQuestions;
        const optionsData = item.dataSetInformation.allOptions;
        const dataSetInstructions = item.dataSetInstructions; // Get dataSetInstructions as a string

        const questionsCSVData = convertToCSV(questionsData);
        const optionsCSVData = convertToCSV(optionsData);

        // Add questions CSV file to the zip
        zip.file(`${datasetName}_questions.csv`, questionsCSVData);

        // Add options CSV file to the zip
        zip.file(`${datasetName}_options.csv`, optionsCSVData);

        // Add dataSetInstructions as a text file to the zip
        zip.file(`${datasetName}_stata_instructions.txt`, dataSetInstructions);
    });

    // Generate the zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
        const zipFilename = 'datasets.zip';
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = zipFilename;
        link.click();
    });
}


export function processText(nText) {
     // Remove HTML tags
     const strippedString = nText.replace(/<[^>]+>/g, "");

     // Replace newline characters with an empty string, including CRLF
     const noNewlinesString = strippedString.replace(/[\n\r]+/g, '');
 
     // Decode HTML entities
     const decodedString = document.createElement("textarea");
     decodedString.innerHTML = noNewlinesString;
     const finalString = decodedString.value;
 
     return finalString;
};


export function processQuestions(nQuestionArray, questionsInformation) {
    let questions = [];
    let choices = [];
    for (var i = 0; i < nQuestionArray.length; i++) {
        let currentQuestionId = nQuestionArray[i]["questionId"];
        let currentQuestionInformation = questionsInformation[currentQuestionId];
        let currentChoices = [];
        let currentChoicesKeys = [];
        let questionToBeAdded = {};
        switch (currentQuestionInformation["questionType"]["type"]) {
            case "DB":

                break;
            case "Matrix":
                let currentSubQuestions = currentQuestionInformation['subQuestions'];
                let currentSubQuestionsKeys = _.keys(currentSubQuestions);
                for (var h = 0; h < currentSubQuestionsKeys.length; h++) {
                    questionToBeAdded = {};
                    let currentSubQuestion = currentSubQuestions[currentSubQuestionsKeys[h]];
                    questionToBeAdded['questionId'] = currentQuestionInformation['questionName'] + '_' + (h + 1);
                    questionToBeAdded['parentQuestionText'] = processText(currentQuestionInformation['questionText']);
                    questionToBeAdded['questionText'] = processText(currentSubQuestion['choiceText']);
                    questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText']) + ' - ' + processText(currentSubQuestion['choiceText']);
                    questionToBeAdded['qualtricsQuestionId'] = currentQuestionId + '.subQuestions.' + currentSubQuestionsKeys[h];
                    questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                    questionToBeAdded['forceResponse'] = currentQuestionInformation['validation']['doesForceResponse'];
                    questionToBeAdded['recode'] = currentSubQuestion['recode'];
                    questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                    questionToBeAdded['generatedByJS'] = false;
                    questionToBeAdded['inExportMap'] = true;
                    questions.push(questionToBeAdded);

                    currentChoices = currentQuestionInformation['choices'];
                    currentChoicesKeys = _.keys(currentChoices);

                    for (var z = 0; z < currentChoicesKeys.length; z++) {
                        let choiceToBeAdded = {};
                        currentChoices = currentQuestionInformation['choices']
                        let currentChoice = currentChoices[currentChoicesKeys[z]];
                        choiceToBeAdded['questionId'] = currentQuestionInformation['questionName'] + '_' + (h + 1);
                        choiceToBeAdded['choiceText'] = processText(currentChoice['choiceText']);
                        choiceToBeAdded['recode'] = currentChoice['recode'];
                        choices.push(choiceToBeAdded);
                    }

                }

                break;
            case "MC":
                questionToBeAdded = {};
                questionToBeAdded['questionId'] = currentQuestionInformation['questionName'];
                questionToBeAdded['parentQuestionText'] = '';
                questionToBeAdded['questionText'] = processText(currentQuestionInformation['questionText']);
                questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText'])
                questionToBeAdded['qualtricsQuestionId'] = currentQuestionId;
                questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                questionToBeAdded['forceResponse'] = currentQuestionInformation['validation']['doesForceResponse'];
                questionToBeAdded['recode'] = -1;
                questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                questionToBeAdded['generatedByJS'] = false;
                questionToBeAdded['inExportMap'] = true;
                questions.push(questionToBeAdded);

                currentChoices = currentQuestionInformation['choices'];
                currentChoicesKeys = _.keys(currentChoices);

                for (var y = 0; y < currentChoicesKeys.length; y++) {
                    let choiceToBeAdded = {};
                    let currentChoice = currentChoices[currentChoicesKeys[y]];
                    choiceToBeAdded['questionId'] = currentQuestionInformation['questionName'];
                    choiceToBeAdded['choiceText'] = processText(currentChoice['choiceText']);
                    choiceToBeAdded['recode'] = currentChoice['recode'];
                    choices.push(choiceToBeAdded);
                }
                break;
            case "Meta":
                currentChoices = currentQuestionInformation['choices'];
                currentChoicesKeys = _.keys(currentChoices);
                for (var j = 0; j < currentChoicesKeys.length; j++) {
                    questionToBeAdded = {};
                    let currentChoice = currentChoices[currentChoicesKeys[j]];
                    questionToBeAdded['questionId'] = currentQuestionInformation['questionName'] + '_' + currentChoice['description'];
                    questionToBeAdded['parentQuestionText'] = processText(currentQuestionInformation['questionText']);
                    questionToBeAdded['questionText'] = processText(currentChoice['choiceText']);
                    questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText']) + ' - ' + processText(currentChoice['choiceText']);
                    questionToBeAdded['qualtricsQuestionId'] = currentQuestionId + '.choices.' + currentChoicesKeys[j];
                    questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                    questionToBeAdded['forceResponse'] = currentQuestionInformation['validation']['doesForceResponse'];
                    questionToBeAdded['recode'] = -1;
                    questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                    questionToBeAdded['generatedByJS'] = false;
                    questionToBeAdded['inExportMap'] = true;
                    questions.push(questionToBeAdded);
                }
                break;
            case "TE":
                questionToBeAdded = {};
                questionToBeAdded['questionId'] = currentQuestionInformation['questionName'];
                questionToBeAdded['parentQuestionText'] = '';
                questionToBeAdded['questionText'] = processText(currentQuestionInformation['questionText']);
                questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText'])
                questionToBeAdded['qualtricsQuestionId'] = currentQuestionId;
                questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                questionToBeAdded['forceResponse'] = currentQuestionInformation['validation']['doesForceResponse'];
                questionToBeAdded['recode'] = -1;
                questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                questionToBeAdded['generatedByJS'] = false;
                questionToBeAdded['inExportMap'] = true;
                questions.push(questionToBeAdded);
                break;

            default:
                break;
        }

    }

    return { processedQuestions: questions, processedOptions: choices };
};

export function processEmbeddedData(nEmbeddedData, jsQuestions) {
    let answer = [];
    _.forEach(jsQuestions, (nData) => {
        const idExists = _.some(nEmbeddedData, { name: nData['questionId'] });
        if (idExists) nData['inExportMap'] = true;
        answer.push(nData);
    })

    _.forEach(nEmbeddedData, (nData) => {
        const idExists = _.some(jsQuestions, { questionId: nData['name'] });
        if (!idExists) {
            let questionToBeAdded = {};
            questionToBeAdded['questionId'] = nData['name'];
            questionToBeAdded['parentQuestionText'] = '';
            questionToBeAdded['questionText'] = nData['name'];
            questionToBeAdded['exportedQuestionText'] = nData['name'];
            questionToBeAdded['qualtricsQuestionId'] = nData['name'];
            questionToBeAdded['qualtricsParentQuestionId'] = nData['name'];
            questionToBeAdded['forceResponse'] = ''
            questionToBeAdded['recode'] = -1;
            questionToBeAdded['questionType'] = "embeddedData";
            questionToBeAdded['generatedByJS'] = false;
            questionToBeAdded['inExportMap'] = true;
            answer.push(questionToBeAdded);
        }
    })

    return answer;
}

// Regular expressions to match empty or falsy values


// Function to check if a variable definition is empty or falsy
function isVariableEmpty(definition) {
    let response = false;
    if (definition === "[];") {
        response = true;
    }
    return response;
}

function parseArguments(argsString) {
    var args = [];
    var currentArg = '';
    var insideString = false;

    for (var i = 0; i < argsString.length; i++) {
        var char = argsString[i];

        if (char === ',' && !insideString) {
            args.push(currentArg.trim());
            currentArg = '';
        } else {
            currentArg += char;
            if (char === '"') {
                insideString = !insideString;
            }
        }
    }

    args.push(currentArg.trim());
    return args;
}

export function processJS(codeString) {
    var resultArray = [];
    



    function resolveValue(value) {
        // Remove comments and trailing spaces
        value = value.replace(/\/\/.*$/, '').trim();

        //Making the response iterative, by splitting the possible substrings

        let firstSplittedValue = value.split(' ');

        for (let b = 0; b < firstSplittedValue.length; b++) {
            let firstSplitValue = firstSplittedValue[b];
            let secondSplittedValue = firstSplitValue.split('+');

            for (let a = 0; a < secondSplittedValue.length; a++) {
                var resolvedValue = secondSplittedValue[a];
                var found = false;
                while (variableDefinitions[resolvedValue] && !found) {


                    if (isVariableEmpty(variableDefinitions[resolvedValue])) {
                        found = true;
                    } else {
                        resolvedValue = variableDefinitions[resolvedValue];
                        found = true

                    }
                }
                secondSplittedValue[a] = resolvedValue;
            }
            firstSplittedValue[b] = secondSplittedValue.join(" + ");
        }

        let joinedValue = firstSplittedValue.join(" ");

        // Keep resolving variables until no more variable references are found


        return joinedValue;
    }


   

    if (typeof codeString === 'string') {
       
        var lines = codeString.split('\n');
        var variableDefinitions = {};
        lines.forEach(function (line) {
            // Check for variable declarations and store them in variableDefinitions
            if (line.includes('var')) {
                var parts = line.trim().split('=');
                if (parts.length === 2) {
                    var declaration = parts[0].trim();
                    var variableName = declaration.replace(/var/, '').trim();
                    var variableValue = resolveValue(parts[1]);
                    variableDefinitions[variableName] = variableValue;
    
                }
            }
    
            if (line.includes('Qualtrics.SurveyEngine.setEmbeddedData')) {
                var startIndex = line.indexOf('(') + 1;
                var endIndex = line.lastIndexOf(')');
                if (startIndex !== -1 && endIndex !== -1) {
                    var argsString = line.slice(startIndex, endIndex);
                    var args = parseArguments(argsString);
                    if (args.length >= 2) {
                        var key = args[0].trim().replace(/"/g, '');
                        var definition = resolveValue(key);
                        resultArray.push(definition);
                    }
                }
            }
        });
    
      } else {
        // Your variable is not a string
        console.log('It is not a string.');
      }

  

    return resultArray;

}

export function clearKey(inputString) {
    const cleanedString = _.replace(inputString, /["';]/g, '');

    // Replace " + " with "/"
    const resultString = _.replace(cleanedString, / \+ /g, '¿');

    // Remove any remaining spaces
    const finalResult = _.replace(resultString, /\s+/g, '');

    return finalResult;
}

export function getKeyType(nKey) {
    let answer = 'non-iterative';
    if (/[.\[\]]/.test(nKey)) answer = 'iterative';
    return answer;
}

export function replaceIterativeString(nKey, nReplacement) {
    let splittedKey = nKey.split('¿');
    for (var i = 0; i < splittedKey.length; i++) {
        let keyPart = splittedKey[i];
        if (/[.\[\]]/.test(keyPart)) keyPart = nReplacement;
        splittedKey[i] = keyPart;
    }
    return splittedKey.join('¿')
}

export function cleanKeySeparator(nKey) {
    const resultString = nKey.replace(/¿/g, '');
    return resultString;

}

export function getJSQuestions(nQuestionArray, nQsfData, questionsInformation) {

    const qsfQuestions = _.groupBy(nQsfData['SurveyElements'], 'PrimaryAttribute');
    let questions = [];
    let counter = 0;
    for (let i = 0; i < nQuestionArray.length; i++) {
        let currentQuestion = nQuestionArray[i];
        if (_.has(qsfQuestions, currentQuestion['questionId'])) {
            let currentQuestionId = currentQuestion['questionId'];
            let currentQsfQuestion = qsfQuestions[currentQuestion['questionId']][0];
            if (_.has(currentQsfQuestion, 'Payload')) {
                if (_.has(currentQsfQuestion['Payload'], 'QuestionJS')) {
                    let questionJS = currentQsfQuestion['Payload']['QuestionJS'];
                    let possibleKeys = processJS(questionJS);
                    if (possibleKeys.length > 0) {

                        for (let j = 0; j < possibleKeys.length; j++) {
                            let cleanedKey = clearKey(possibleKeys[j]);
                            let keyType = getKeyType(possibleKeys[j]);

                            let currentChoices = [];
                            let currentChoicesKeys = [];
                            let questionToBeAdded = {};

                            let currentQuestionInformation = questionsInformation[currentQuestion['questionId']];

                            switch (currentQuestionInformation["questionType"]["type"]) {

                                case "Matrix":
                                    currentChoices = currentQuestionInformation['choices'];
                                    currentChoicesKeys = _.keys(currentChoices);
                                    let currentChoice = {};
                                    if (keyType == 'iterative') {
                                        for (let h = 0; h < currentChoicesKeys.length; h++) {
                                            try {
                                                currentChoice = currentChoices[currentChoicesKeys[h]];
                                                questionToBeAdded = {};
                                                questionToBeAdded['questionId'] = cleanKeySeparator(replaceIterativeString(cleanedKey, (h + 1)));
                                                questionToBeAdded['parentQuestionText'] = processText(currentQuestionInformation['questionText']);
                                                questionToBeAdded['questionText'] = processText(currentChoice['choiceText']);
                                                questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText']) + ' - ' + processText(currentChoice['choiceText']);
                                                questionToBeAdded['qualtricsQuestionId'] = currentQuestionId + '.embeddedData.' + cleanKeySeparator(replaceIterativeString(cleanedKey, (h + 1)));
                                                questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                                                questionToBeAdded['forceResponse'] = '';
                                                questionToBeAdded['recode'] = currentChoice['recode'];
                                                questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                                                questionToBeAdded['generatedByJS'] = true;
                                                questionToBeAdded['inExportMap'] = false;
                                                questions.push(questionToBeAdded)

                                            } catch (e){
                                                console.log('error with ' + currentQuestionId + ', iterative question. '+ e);
                                            }

                                        }
                                    }
                                    else {
                                        try {
                                            
                                            let numbersInKey = cleanedKey.match(/\d+(\.\d+)?/g) || [];
                                            
                                            if(numbersInKey.length > 0){
                                                currentChoice = (_.filter(currentChoices, { recode: numbersInKey[0] }))[0];
                                                if(!_.isEmpty(currentChoice)){
    
                                                    questionToBeAdded = {};
                                                    questionToBeAdded['questionId'] = cleanKeySeparator(replaceIterativeString(cleanedKey,''));
                                                    questionToBeAdded['parentQuestionText'] = processText(currentQuestionInformation['questionText']);
                                                    questionToBeAdded['questionText'] = processText(currentChoice['choiceText']);
                                                    questionToBeAdded['exportedQuestionText'] = processText(currentQuestionInformation['questionText']) + ' - ' + processText(currentChoice['choiceText']);
                                                    questionToBeAdded['qualtricsQuestionId'] = currentQuestionId + '.embeddedData.' + cleanKeySeparator(replaceIterativeString(cleanedKey));
                                                    questionToBeAdded['qualtricsParentQuestionId'] = currentQuestionId;
                                                    questionToBeAdded['forceResponse'] = '';
                                                    questionToBeAdded['recode'] = currentChoice['recode'];
                                                    questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                                                    questionToBeAdded['generatedByJS'] = true;
                                                    questionToBeAdded['inExportMap'] = false;
                                                    questions.push(questionToBeAdded)
    
                                                }
                                                else{
                                                    questionToBeAdded = {}
                                                    questionToBeAdded['questionId'] = cleanKeySeparator(replaceIterativeString(cleanedKey, ''));
                                                    questionToBeAdded['parentQuestionText'] = '';
                                                    questionToBeAdded['questionText'] = '';
                                                    questionToBeAdded['exportedQuestionText'] = '';
                                                    questionToBeAdded['qualtricsQuestionId'] = '';
                                                    questionToBeAdded['qualtricsParentQuestionId'] = '';
                                                    questionToBeAdded['forceResponse'] = '';
                                                    questionToBeAdded['recode'] = -1;
                                                    questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                                                    questionToBeAdded['generatedByJS'] = true;
                                                    questionToBeAdded['inExportMap'] = false;
                                                    questions.push(questionToBeAdded)
    
                                                }

                                            } else {
                                                questionToBeAdded = {}
                                                questionToBeAdded['questionId'] = cleanKeySeparator(replaceIterativeString(cleanedKey, ''));
                                                questionToBeAdded['parentQuestionText'] = '';
                                                questionToBeAdded['questionText'] = '';
                                                questionToBeAdded['exportedQuestionText'] = '';
                                                questionToBeAdded['qualtricsQuestionId'] = '';
                                                questionToBeAdded['qualtricsParentQuestionId'] = '';
                                                questionToBeAdded['forceResponse'] = '';
                                                questionToBeAdded['recode'] = -1;
                                                questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                                                questionToBeAdded['generatedByJS'] = true;
                                                questionToBeAdded['inExportMap'] = false;
                                                questions.push(questionToBeAdded)
                                            }


                                           
                                           

                                        } catch (e) {
                                            console.log('error with ' + currentQuestionId + ': ' + e);
                                        }
                                    }
                                    break;
                                default:
                                    questionToBeAdded = {}
                                    questionToBeAdded['questionId'] = cleanKeySeparator(replaceIterativeString(cleanedKey, ''));

                                    questionToBeAdded['parentQuestionText'] = '';
                                    questionToBeAdded['questionText'] = '';
                                    questionToBeAdded['exportedQuestionText'] = '';
                                    questionToBeAdded['qualtricsQuestionId'] = '';
                                    questionToBeAdded['qualtricsParentQuestionId'] = '';
                                    questionToBeAdded['forceResponse'] = '';
                                    questionToBeAdded['recode'] = -1;
                                    questionToBeAdded['questionType'] = currentQuestionInformation["questionType"]["type"];
                                    questionToBeAdded['generatedByJS'] = true;
                                    questionToBeAdded['inExportMap'] = false;
                                    questions.push(questionToBeAdded)
                                    break;

                            }

                        }

                        counter++;
                    }


                }

            }

        }
    }
    return questions;
}

export function processDataSet(dataSetDefinition, nQsfData) {

    let fullQsfData = nQsfData;

  

    let tenativeFlow = _.filter(fullQsfData['SurveyElements'], {"Element": "FL"})[0]['Payload']['Flow'];



    let { blocks, flow, questions, embeddedData } =
        dataSetDefinition["result"];

   

    let questionsInOrder = _.reject(getQuestionsFromFlow(flow, blocks), {
        type: "PageBreak",
    });

  

    let questionsInOrderQsf = _.reject(getQuestionsFromFlowQsf(tenativeFlow, blocks), {
        type: "PageBreak",
    });


    let jsQuestions = [];
    try {
        jsQuestions = (getJSQuestions(questionsInOrderQsf, nQsfData, questions));
    } catch (e) {
        console.log('Error in', dataSetDefinition['name'] + 'error: ' + e)
    }

    let responseMetaDataQuestions = [];
    let { processedQuestions, processedOptions } = processQuestions(questionsInOrder, questions);

    let embeddedDataAsQuestions = processEmbeddedData(embeddedData, jsQuestions);

    let allQuestions = _.concat(responseMetaDataQuestions, processedQuestions, embeddedDataAsQuestions);


    return { allQuestions: allQuestions, allOptions: processedOptions };
};

export function processStataInstructions(nDataSetInformation) {
    let allQuestions = nDataSetInformation['allQuestions'];
    let allOptions = nDataSetInformation['allOptions'];
    let grouppedOptions = _.groupBy(allOptions, 'questionId');

    let answer = '';
    for (var i = 0; i < allQuestions.length; i++) {
        let currentQuestion = allQuestions[i];


        let currentOptions = _.get(grouppedOptions, currentQuestion.questionId);
        if (currentQuestion.questionType === 'embeddedData') currentQuestion.exportedQuestionText = currentQuestion.questionId

        if (currentOptions !== undefined) {
            answer += 'label variable ' + currentQuestion.questionId + ' "' + currentQuestion.exportedQuestionText + '" \n';
            let optionsText = 'label define ' + currentQuestion.questionId + '_options '
            for (var j = 0; j < currentOptions.length; j++) {
                let currentOption = currentOptions[j];

                optionsText += parseInt(currentOption.recode) + ' "' + currentOption.choiceText + '" ';
            }
            optionsText += '\nlabel values ' + currentQuestion.questionId + ' ' + currentQuestion.questionId + '_options\n\n'
            answer += optionsText;
        } else {
            answer += 'label variable ' + currentQuestion.questionId + ' "' + currentQuestion.exportedQuestionText + '"\n\n';

        }

    }
    answer += '\n\n';
    return answer;
}

export function getQuestionsFromFlow(nFlow, nBlocks) {
    let questionsInOrder = [];
    if (nFlow) {
        for (var i = 0; i < nFlow.length; i++) {
            let currentFlowItem = nFlow[i];
            let blockQuestions = nBlocks[currentFlowItem["id"]];
            switch (currentFlowItem["type"]) {
                case "Block":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        blockQuestions["elements"]
                    );
                    break;
                case "BlockRandomizer":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        getQuestionsFromFlow(currentFlowItem["flow"], nBlocks)
                    );
                    break;
                case "Branch":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        getQuestionsFromFlow(currentFlowItem["flow"], nBlocks)
                    );
                    break;

                default:
                    break;
            }
        }
    } else {
        let allBlocks = nBlocks;
        let allBlocksKeys = _.keys(allBlocks);
        for (var o = 0; o < allBlocksKeys.length; o++) {
            let blockQuestions = allBlocks[allBlocksKeys[o]];
            questionsInOrder = _.concat(questionsInOrder, blockQuestions["elements"])
        }
    }

    return questionsInOrder;
};


export function getQuestionsFromFlowQsf(nFlow, nBlocks) {
    let questionsInOrder = [];
    if (nFlow) {
        for (var i = 0; i < nFlow.length; i++) {
            let currentFlowItem = nFlow[i];
            let blockQuestions = nBlocks[currentFlowItem["ID"]];
            switch (currentFlowItem["Type"]) {
                case "Standard":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        blockQuestions["elements"]
                    );
                    break;
                case "BlockRandomizer":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        getQuestionsFromFlowQsf(currentFlowItem["Flow"], nBlocks)
                    );
                    break;
                case "Branch":
                    questionsInOrder = _.concat(
                        questionsInOrder,
                        getQuestionsFromFlowQsf(currentFlowItem["Flow"], nBlocks)
                    );
                    break;

                default:
                    break;
            }
        }
    } else {
        let allBlocks = nBlocks;
        let allBlocksKeys = _.keys(allBlocks);
        for (var o = 0; o < allBlocksKeys.length; o++) {
            let blockQuestions = allBlocks[allBlocksKeys[o]];
            questionsInOrder = _.concat(questionsInOrder, blockQuestions["elements"])
        }
    }

    return questionsInOrder;
};
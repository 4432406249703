import React, { useCallback, useState } from "react";

import { filter, forEach, includes, indexOf, isUndefined, keys, map, isNull } from "lodash";
import { read, utils } from "xlsx";

import { DownOutlined } from "@ant-design/icons";

import { Button, Card, Col, Drawer, Dropdown, Menu, Result, Row, Steps, Table, Tag, Upload } from "antd";

import excelFile from "./studentsUploadExample.xlsx";

import ImportStudentsStore from "./ImportStudentsStore.js";

import ImportFooter from "./ImportFooter";
import Results from "./Results";

const { Dragger } = Upload;
const { Step } = Steps;

const ImportStudents = ({ onClose, isVisible, showRefreshModal }) => {
  const { expectedKeys, uploadedSpreadsheetData, steps, unmatchedFields, keysAvailable } = ImportStudentsStore;

  const [current, setCurrent] = useState(0);
  const [actionEmails, setActionEmails] = useState("force");
  const [actionGroups, setActionGroups] = useState("force");

  const [, updateState] = useState();

  const forceUpdate = useCallback(() => updateState({}, []));

  const readFile = (file) => {
    const selectedFile = file.file;
    let correctAmountOfRows = true;

    if (selectedFile && !uploadedSpreadsheetData) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = read(data, { type: "binary" });
        forEach(workbook.SheetNames, (sheet) => {
          let rowObject = utils.sheet_to_row_object_array(workbook.Sheets[sheet]);

          forEach(expectedKeys, (key) => {
            if (!includes(keys(rowObject[0]), key)) {
              correctAmountOfRows = false;
            }
          });

          if (keys(rowObject[0]).length !== expectedKeys.length) correctAmountOfRows = false;

          const preparedData = filter(rowObject, (record) => !includes(record, "__"));
          ImportStudentsStore.setUploadedSpreadsheetData(preparedData);
        });

        if (correctAmountOfRows) {
          nextStep();
        } else {
          alert(`Uploaded file does not match to our template`);
          ImportStudentsStore.setUploadedSpreadsheetData(null);
          onClose();
        }
      };
    }
  };

 

  const nextStep = () => setCurrent((prev) => prev + 1);
  const previousStep = () => setCurrent((prev) => prev - 1);

  const dataSource =
    uploadedSpreadsheetData &&
    map(keys(uploadedSpreadsheetData[0]), (key, index) => {
      if (includes(key, "__")) return;
      let isMatched = includes(expectedKeys, key);

      if (!isMatched) ImportStudentsStore.setUnmatchedFields(true);

      return {
        key: index,
        sourceColumn: key,
        targetField: isMatched ? key : "select",
        status: isMatched ? "matched" : "not matched",
      };
    }).filter((element) => !isUndefined(element));

  const columns = [
    {
      title: "Source column",
      dataIndex: "sourceColumn",
      key: "sourceColumn",
    },
    {
      title: "Target Field",
      dataIndex: "targetField",
      key: "targetField",

      render: (_, { targetField }) => {
        return (
          <Dropdown overlay={menu} size={"large"}>
            <Button size={"middle"}>
              {targetField}
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color = status === "matched" ? "green" : "red";

        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  const menu = () => {
    const items = map(keysAvailable, (key) => {
      return {
        key: key,
        label: key,
      };
    });

    return <Menu items={items} />;
  };

  // const findErrorCord = () => {
  //   const errorMessages = [];

  //   forEach(uploadedSpreadsheetData, (row) => {
  //     forEach(row, (field, fieldName) => {
  //       if (isEmpty(field) && fieldName !== "__rowNum__") {
  //         errorMessages.push({
  //           type: fieldName,
  //           value: field,
  //         });
  //       }

  //       if (fieldName === "groups") {
  //         forEach(field.split(","), (group) => {
  //           const groups = map(StudentsManagementStore.groups, (group) => group.key);

  //           if (!includes(groups, group)) {
  //             errorMessages.push({
  //               type: fieldName,
  //               value: field,
  //             });
  //           }
  //         });
  //       }

  //       if (fieldName === "email") {
  //         let userWithFollowingEmailExist = false;
  //         forEach(StudentsManagementStore.students, (student) => {
  //           if (student.email === field) userWithFollowingEmailExist = true;
  //         });

  //         if (userWithFollowingEmailExist)
  //           errorMessages.push({
  //             type: fieldName,
  //             value: field,
  //           });
  //       }
  //     });
  //   });

  //   // StudentsManagementStore.setErrorMessages(errorMessages);
  // };

  const StepOne = () => {
    return (
      <Col span={24}>
        <Row gutter={[16, 48]} justify="center" align="center">
          <Col span={16}>
            <Result
              title={"Upload Spreasheet"}
              extra={
                <a href={excelFile} download={true}>
                  <Button type="primary" key="console">
                    {"Download Template"}
                  </Button>
                </a>
              }
            />
          </Col>
        </Row>

        <Row gutter={[16, 48]} justify="center">
          <Col span={16}>
            <Dragger
              name="file"
              listType="text"
              accept=".xlsx , .csv"
              beforeUpload={(file) => {
                return false;
              }}
              onChange={readFile}
            >
              <p className="ant-upload-text">{"click to upload"}</p>
              <p className="ant-upload-hint">{"bulk upload"}</p>
            </Dragger>
          </Col>
        </Row>
      </Col>
    );
  };

  const StepTwo = () => {
    const handleChangeTargetField = (record, event) => {
      if (record.status === "not matched") {
        const field = event.target.textContent;
        const unexpectedField = record.sourceColumn;

        if (includes(expectedKeys, record.sourceColumn)) return;

        if (field !== "select" && field !== unexpectedField) {
          forEach(uploadedSpreadsheetData, (userData) => {
            userData[field] = userData[unexpectedField];
            delete userData[unexpectedField];
          });
        }

        const unmatchedFieldIndex = indexOf(dataSource, (record) => record.status !== "matched");

        if (unmatchedFieldIndex !== -1) {
          ImportStudentsStore.setUnmatchedFields(true);
        } else {
          ImportStudentsStore.setUnmatchedFields(false);
        }

        forceUpdate();
      }
    };

    return (
      <Col span={24}>
        <Row gutter={[16, 48]} justify="center">
          <Col span={24}>
            <Result title={"Spreadsheet Uploaded"} />
          </Col>
        </Row>

        <Row gutter={[16, 48]} justify="center">
          <Col span={24}>
            <Card title={unmatchedFields ? "columns found" : null} style={{ textAlign: "center" }} bordered={false}>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                size={"small"}
                onRow={(record) => {
                  return {
                    onClick: (event) => handleChangeTargetField(record, event),
                  };
                }}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    );
  };

  const StepThree = () => {
    // findErrorCord();

    const subjects = map(uploadedSpreadsheetData, (record) => {
      const modifiedSubjectData = {


        
        id: record.id,
        idGroup: record.idGroup || '',
        firstName: record.firstName,
        lastName: record.lastName || '',
        name: record.lastName?record.firstName.concat(" ", record.lastName || ''):record.firstName,
        group: record["group"],
        level: record.level || '',
        line: record.line || '',
        listNumber: record.listNumber || '',
        idMonkStudent: record.idMonkStudent || '',
        idMonk: record.idMonk || '',

        idMonkGroup: record.idMonkGroup || '',
        idTea: record.idTea || '',
       
        
        
        noConsent: record["noConsent"] === "false" ? false : true,
        noConsentLong: record["noConsentLong"] === "false" ? false : true,
        consentReceived: record["consentReceived"] === "false" ? false : true
       
      };

      return modifiedSubjectData;
    });

    ImportStudentsStore.setSubjectsToCreate(subjects);

    return (
      <Row gutter={[16, 48]} justify="center" align="center">
        <Results actionEmails={actionEmails} actionGroups={actionGroups} setActionEmails={setActionEmails} setActionGroups={setActionGroups} />
      </Row>
    );
  };

  const StepFour = () => {
    setTimeout(() => {
      onClose();
      setCurrent(0);
      ImportStudentsStore.setUploadedSpreadsheetData(null);
      ImportStudentsStore.setStatus(!isNull(ImportStudentsStore.errorMessages) ? "success" : "warning");
      
      forceUpdate();
    }, 2000);

    return (
      <Row gutter={[16, 48]} justify="center" align="center">
        <Col span={16}>
          <Result title={ImportStudentsStore.message} status={ImportStudentsStore.status} className="positive-result-message" />
        </Col>
      </Row>
    );
  };

  return (
    <Drawer title="Students upload" placement="bottom" open={isVisible} size={"large"} onClose={onClose} closable={true} className="upload-drawer">
      <Row gutter={[16, 48]} justify="center">
        <Col span={24} style={{ justifyContent: "center" }}>
          <Steps current={current} size="small">
            {steps.map((item) => {
              return <Step key={item.title} title={item.title} />;
            })}
          </Steps>
        </Col>
      </Row>

      {current === 0 && <StepOne />}

      {current === 1 && <StepTwo />}

      {current === 2 && <StepThree />}

      {current === 3 && <StepFour />}

      <ImportFooter nextStep={nextStep} previousStep={previousStep} step={current} actionGroups={null} actionEmails={null} showRefreshModal={showRefreshModal} />
    </Drawer>
  );
};

export default ImportStudents;

import React, { useState, useEffect } from 'react';
import { Select, Button, Row, Col, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const EncryptingDynamicInputs = ({ onInputChange, updateData }) => {
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    if (Array.isArray(updateData) && updateData.length > 0) {
      setInputs(updateData);
    } else {
      setInputs(['']);
    }
  }, [updateData]);

  const handleInputChange = (index, value) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    setInputs(updatedInputs);
    onInputChange(updatedInputs); // Pass the updated inputs to the parent component
  };

  const handleAddInput = () => {
    setInputs([...inputs, '']);
  };

  const handleRemoveInput = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
    onInputChange(updatedInputs); // Update the parent component with the updated inputs
  };

  return (
    <div>
      <Button onClick={handleAddInput}>
        <PlusOutlined />
      </Button>
      {inputs.map((input, index) => (
        <div key={index}>
          <Row gutter={16}>
            <Col span={12}>
              <Input
                placeholder="Field Name"
                onBlur={(event) =>
                  handleInputChange(index, event.target.value)
                }
                value={input}
                onChange={(event) =>
                  handleInputChange(index, event.target.value)
                }
              />
            </Col>
            {inputs.length > 1 && (
              <Col span={6}>
                <Button onClick={() => handleRemoveInput(index)}>
                  <DeleteOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default EncryptingDynamicInputs;

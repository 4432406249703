import React from "react";
import { Tabs, Layout, Row } from "antd";
import FolderSelector from "./FolderSelector";
import GeneratorComponent from "./GeneratorComponent";
import { PageContainer, ProCard } from "@ant-design/pro-components";
const { Header, Footer, Content } = Layout;

const Codebooks = () => {
    const items = [
        {
          key: '1',
          label: 'Definitions builder from survey backups',
          children: <FolderSelector />,
        },
        {
          key: '2',
          label: 'Create stata files from questions & options upload',
          children: <GeneratorComponent />,
        },
      ];
    
    return(
    <>
    <PageContainer header={{ title: "Initial codebook and stata file generator", ghost: true }}>
                <ProCard direction="column" ghost gutter={[0, 16]}>
          <ProCard style={{ minHeight: 200 }}>
             <Tabs defaultActiveKey="1" items={items} />
            </ProCard>
            </ProCard>
     
      </PageContainer>
       
    </>);
}

export default Codebooks;
const TooltipTexts = {
    location: {
        dataSource: "Data source can e.g. be 'qualtrics', 'monk', etc. Use lowercase",
        dataSet: "Data set are the different datasets within the datasource. E.g. in the case of 'qualtrics' could be individual surveys. If only one survey is listed, add the dataset with project and region prefix such as 'mat_cat_monk'. Use lowercase and split by underscore."
    },
    type: "'Local': Datasets that are uploaded manually to the server. 'Remote (Versioning)': Files that are downloaded remotely in their entirety (i.e. not split by dates, e.g. Qualtrics). 'Remote (Appending)': Files that are downloaded as subsets, e.g. Pentabilities App Evaluations.",
    paths: 'Lorem ipsum',
    fields: 'Lorem ipsum',
};

export default TooltipTexts;  
import React, { useState } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import Cookies from "js-cookie";

import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from "antd";
import logo from "../img/se_white.png";
import UserStore from "./UserStore";
import { checkUserRole } from "./rolesUtils"; // Import the role checking function
const { Header, Content, Footer } = Layout;

const PrivateRoute = ({
  component: Component,
  userRole,
  allowedRoles,
  ...rest
}) => {
  let menuOptions = [
    {
      label: (
        <a href="/docs" rel="noopener noreferrer">
          Grades & attendance
        </a>
      ),
      key: "docs",
    },
  ];
  if (userRole === "admin") {
    menuOptions.push({
      label: (
        <a href="/manager" rel="noopener noreferrer">
          Data
        </a>
      ),
      key: "manager",
    });
  }
  if (userRole === "viewer" || userRole === "admin") {
    menuOptions.push(
      {
        label: (
          <a href="/followup" rel="noopener noreferrer">
            Consents
          </a>
        ),
        key: "followup",
      },

      {
        label: (
          <a href="/data" rel="noopener noreferrer">
            Data flow
          </a>
        ),
        key: "data",
      },
      {
        label: (
          <a href="/codebooks" rel="noopener noreferrer">
            Codebooks
          </a>
        ),
        key: "codebooks",
      }
    );
  }

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get("userId") != null &&
          Cookies.get(`userId`) !== undefined ? (
            <Layout>
              <Header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  width: "100%",
                }}
              >
                <Row align={"middle"}>
                  <Col style={{ margin: "0 24px 0 0" }}>
                    <center>
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          float: "left",
                          width: "auto",
                          height: 64,
                        }}
                      />
                    </center>
                  </Col>
                  <Col style={{ flex: "1" }}>
                    <Menu
                      theme="dark"
                      mode="horizontal"
                      defaultSelectedKeys={["2"]}
                      items={menuOptions}
                    />
                  </Col>
                  <Col style={{ flex: "0" }}>
                    <Menu
                      theme="dark"
                      mode="horizontal"
                      items={[
                        {
                          label: (
                            <a
                              href="#"
                              rel="noopener noreferrer"
                              onClick={() => {
                                UserStore.logoutUser();
                              }}
                            >
                              Logout
                            </a>
                          ),
                          key: "logout",
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Header>
              <Content
                className="site-layout"
                style={{
                  padding: "0 50px",
                }}
              >
                <div
                  style={{
                    padding: 24,
                    minHeight: 380,
                  }}
                >
                  <Component {...props} />

                  {checkUserRole(userRole, allowedRoles) ? (
                   <></>
                  ) : (
                    <Redirect to="/docs" />
                  )}
                </div>
              </Content>
              <Footer
                style={{
                  textAlign: "center",
                }}
              >
                Fundació Bofill ©2023
              </Footer>
            </Layout>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { db, auth } from "../utilities/Firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Table, Badge, Space, Spin, Button, Tabs } from 'antd';
import { PageContainer } from "@ant-design/pro-components";
import { DatabaseOutlined, WindowsOutlined } from '@ant-design/icons';
import CardsPanel from "./components/CardsPanel";

const ETL = () => {

  const [tableFilters, setTableFilters] = useState({});
  const [tableData, setTableData] = useState();
  const [panelsData, setPanelsData] = useState({});
  const [expandedRows, setExpandedRows] = useState();
  const [activeTab, setActiveTab] = useState("UPF Marvin");

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  // DEFINING ANT DESIGN TABLE COLUMNS
  const tableColumns = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      filters: tableFilters.projectsFilter,
      onFilter: (value, record) => record.project.indexOf(value) === 0,
      sorter: (a, b) => {return a.project.localeCompare(b.project)}
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      filters: tableFilters.dataSourceFilter,
      onFilter: (value, record) => record.source.indexOf(value) === 0,
      sorter: (a, b) => {return a.source.localeCompare(b.source)}
    },
    {
      title: 'Dataset',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {return a.name.localeCompare(b.name)}
    },
    {
      title: 'Last Executed',
      dataIndex: 'execution',
      key: 'execution',
    },
    {
      title: '1. Rows in raw',
      dataIndex: 'entries',
      key: 'entries',
    },
    {
      title: '2. Encrypted IDs (unique)',
      dataIndex: 'matchedIDs',
      key: 'matchedIDs',
    },
    {
      title: '3. Rows with allowed Ids',
      dataIndex: 'consents',
      key: 'consents',
    },
    {
      title: '4. Created files',
      dataIndex: 'createdFiles',
      key: 'createdFiles',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        if (status !== 'N/A') {
          return (
            <Space size="small">
              {Object.keys(status).map((step) => (
                <Badge
                  status={status[step] === 'OK' ? 'success' : status[step] === 'KO' ? 'error' : status[step] === 'PENDING' ? 'warning'
                    : 'default'}
                  key={step}
                />
              ))}
            </Space>
          );
        } else {
          return 'N/A';
        }
      },
    },
  ];


  // LOAD DEFAULT DATA (OVERVIEWS FOR THE TABLE)
  useEffect(() => {
    loadProjects();
    loadData();
  }, []);


  // LOAD LIST OF PROJECTS AND SAVE IN TABLE FILTERS
  const loadProjects = () => {
    const functions = getFunctions();
    httpsCallable(
      functions,
      `getETLProjects`
    )({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
    }).then((r) => {
      console.log('projects loaded');
      const projects = (r.data).map((project) => ({
        text: project.charAt(0).toUpperCase() + project.slice(1),
        value: project,
      }));
      setTableFilters(prevState => ({
        ...prevState,
        projectsFilter: projects
      }));
    });
  };


  // GET LIST OF DATA SOURCES AND SAVE IT TO TABLE FILTERS
  const getDataSourcesFilter = (data) => {
    const mergedSources = [];
    for (let proj in data) {
      if ((mergedSources.indexOf(data[proj].dataSource)) === -1) {
        mergedSources.push(data[proj].dataSource)
      }
    }
    const sourcesFilter = mergedSources.map((src) => ({
      text: src.charAt(0).toUpperCase() + src.slice(1),
      value: src,
    }));

    setTableFilters(prevState => ({
      ...prevState,
      dataSourceFilter: sourcesFilter
    }));
  }

  // LOAD ALL DATASETS OVERVIEWS
  const loadData = () => {
  const functions = getFunctions();
  httpsCallable(functions, `getDataSetsOverviews`)({
    token: "AC335ED6B3FE8C936C7F6D6AD3D51",
  }).then((r) => {
    console.log('data loaded');
    getDataSourcesFilter(r.data);
    
    // Sort the data before setting it as the initial table data
    const sortedTableData = createTableData(r.data).sort((a, b) => {
      const projectComparison = a.project.localeCompare(b.project);
      if (projectComparison !== 0) {
        return projectComparison;
      }
      
      const sourceComparison = a.source.localeCompare(b.source);
      if (sourceComparison !== 0) {
        return sourceComparison;
      }
      
      return a.name.localeCompare(b.name);
    });
    
    setTableData(sortedTableData);
  });
};


  // LOAD CURRENT DATASET
  const loadCurrentDataSet = (project, source, dataSet) => {
    const functions = getFunctions();

    httpsCallable(functions, `getCurrentDataSet`)({
      token: "AC335ED6B3FE8C936C7F6D6AD3D51",
      project: project,
      dataSource: source,
      dataSet: dataSet
    })
      .then((r) => {
        //  console.log('loaded data for current', r.data);
        setPanelsData((prevData) => ({
          ...prevData,
          [dataSet]: r.data,
        }));

      });
  }


  // FROMAT NUMBERS (is called on all numbers within the cards)
  const formatNumber = (numberToDisplay) => {
    // console.log('TEST INPUT NUM', numberToDisplay, typeof numberToDisplay)
    if (numberToDisplay === 'N/A') {
      return 'N/A'
    } else {
      let formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: true
      }).format(Number(numberToDisplay));
      //  console.log('FORMATTED', formattedNumber, typeof formattedNumber)
      return formattedNumber;
    }
  }


  //printing filters and sorting parameters
  const onChange = (pagination, filters, sorter, extra) => {
    //console.log('params', pagination, filters, sorter, extra);
  };


  //sort dataSets displayed by default 
  const sortDefaultDataSets = (arr) => {
    arr.sort((a, b) => {
      const firstLetterA = a.charAt(0).toUpperCase();
      const firstLetterB = b.charAt(0).toUpperCase();

      return firstLetterA.localeCompare(firstLetterB);
    });
  }


  // ASSIGNING ETL DATA FROM FIREBASE TO THE TABLE DATA 
  const createTableData = (overviews) => {

    let newTableData = [];
    let dataSetsArr = Object.keys(overviews);
    sortDefaultDataSets(dataSetsArr);

    for (let i = 0; i < dataSetsArr.length; i++) {
      let dataSetName = dataSetsArr[i];
      let executionInfo, entriesInfo, matchedIDsInfo, consentsInfo, createdFilesInfo, projectInfo, sourceInfo, statusInfo;

      executionInfo = (overviews[dataSetName] && overviews[dataSetName].latestExecutionDate) ? overviews[dataSetName].latestExecutionDate : 'N/A';
      entriesInfo = (overviews[dataSetName] && overviews[dataSetName].step1rows) ? overviews[dataSetName].step1rows : 'N/A';
      matchedIDsInfo = (overviews[dataSetName] && overviews[dataSetName].step2UniqEncIds) ? overviews[dataSetName].step2UniqEncIds : 'N/A';
      consentsInfo = (overviews[dataSetName] && overviews[dataSetName].step3RowsWithAllowedValues) ? overviews[dataSetName].step3RowsWithAllowedValues : 'N/A';
      createdFilesInfo = (overviews[dataSetName] && overviews[dataSetName].step4CreatedFiles) ? overviews[dataSetName].step4CreatedFiles : 'N/A';
      projectInfo = (overviews[dataSetName] && overviews[dataSetName].project) ? overviews[dataSetName].project : 'N/A';
      sourceInfo = (overviews[dataSetName] && overviews[dataSetName].dataSource) ? overviews[dataSetName].dataSource : 'N/A';
      statusInfo = (overviews[dataSetName] && overviews[dataSetName].stepStatus) ? overviews[dataSetName].stepStatus : 'N/A';

      newTableData[i] = {
        key: dataSetsArr[i],
        name: dataSetsArr[i],
        execution: executionInfo,
        entries: /^\d+$/.test(entriesInfo) ? formatNumber(entriesInfo) : entriesInfo,                       //Only format the number, if it's a number or string containing only numbers
        matchedIDs: /^\d+$/.test(matchedIDsInfo) ? formatNumber(matchedIDsInfo) : matchedIDsInfo,           //Only format the number, if it's a number or string containing only numbers
        consents: /^\d+$/.test(consentsInfo) ? formatNumber(consentsInfo) : consentsInfo,                   //Only format the number, if it's a number or string containing only numbers
        createdFiles: /^\d+$/.test(createdFilesInfo) ? formatNumber(createdFilesInfo) : createdFilesInfo,   //Only format the number, if it's a number or string containing only numbers
        project: projectInfo,
        source: sourceInfo,
        status: statusInfo
      }
    }
    return newTableData
  }


  // WHEN ROW EXPANDED, LOAD DATA FOR THE CORRESPONDING DATASET AND ADD IT TO PANELS DATA + ADD THE KEY TO THE LIST OF EXPANDED ROWS
  const handleExpand = (expanded, record) => {
    if (expanded) {
      loadCurrentDataSet(record.project, record.source, record.key);
    } else {
      setPanelsData((prevData) => ({
        ...prevData,
        [record.key]: null,
      }));
    }

    setExpandedRows((previouslyExpanded) => ({
      ...previouslyExpanded,
      [record.key]: expanded,
    }));

  };

  // Render the table with filtered data based on the active tab
  let filteredTableData = [];
  if (tableData) {
    filteredTableData = tableData.filter((record) => {
      if (activeTab === "UPF Marvin") {
        return !record.project.includes("-bofillServer");
      } else if (activeTab === "Bofill Windows Server") {
        return record.project.includes("-bofillServer");
      }
      return true; // If no tab matches, show all rows
    });
  }


  // RETURN DASHBOARD

  return (
    <div className="EtlDashboard"
      style={{ borderRadius: '8px', overflow: 'hidden', background: '#F5F7FA' }}>
      <PageContainer
        header={{
          title: "ETL Dashboard",
          ghost: true,
        }}
      >
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <a href="/export-config-generator" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
            <Button type="primary">Go to Config Generator</Button>
          </a>
        </div>

        <div style={{ display: 'flex' }}>
          <Tabs activeKey={activeTab} onChange={handleTabChange}>
            <Tabs.TabPane
              tab={<span><DatabaseOutlined style={{ marginRight: 8 }} /> UPF Marvin </span>}
              key="UPF Marvin"
            />
            <Tabs.TabPane
              tab={<span><WindowsOutlined style={{ marginRight: 8 }} /> Bofill Windows Server </span>}
              key="Bofill Windows Server"
            />
          </Tabs>
        </div>

        {!tableData ?
          <div className="LoadingPanel"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              height: '30vh',
            }}>
            <Spin size='large' />
          </div>

          :

          <div className='EtlTable'
            style={{
              display: 'flex',
              // width: '70vw',
              justifyContent: 'center',
              alignItems: 'center',
              // margin: 'auto'
            }}>

            <Table
              columns={tableColumns}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => {
                  const currentKey = record.key
                  return (
                    <div>
                      {/* IF ROW IS EXPANDED AND THE DATASET´S DATA IS SAVED IN PANELSDATA, DISPLAY THE CARDS PANEL */}
                      {expandedRows[currentKey] && panelsData[currentKey] ?
                        <CardsPanel
                          formatNumber={formatNumber}
                          rowKey={record.key}
                          key={record.key}
                          currentDataSet={panelsData[currentKey]}
                        />
                        : expandedRows[currentKey] && !panelsData[currentKey] ?
                          <div
                            key={`spinner-${record.key}`}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Spin />
                          </div>
                          : ''
                      }
                    </div>
                  );
                },
                onExpand: (expanded, record) => { handleExpand(expanded, record) },
                expandRowByClick: true,
              }}
              dataSource={filteredTableData}
              onChange={onChange}
            />
          </div>
        }
        {/* </div> */}
      </PageContainer>
    </div>
  )
}

export default ETL;
import React, { useState } from "react";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from '../utilities/Firebase';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import UserStore from '../utilities/UserStore';
import { Row, Col, message } from "antd";
import banner from '../img/banner.jpg'


const Login = (props) => {
  const [loginType, setLoginType] = useState("account");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const onSubmit = async (values) => {
    setLoading(true);

    await signInWithEmailAndPassword(auth, values.username, values.password)
      .then(async (r) => {
        const user = r.user.uid;
        Cookies.set("userId", r.user.uid);
        UserStore.loadData().then(() => {
          Cookies.set("userRole", UserStore.getCurrentUserRole());
          Cookies.set('userScope', UserStore.getCurrentUserScope());
          window.location.replace("/");
        })
        
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
        console.log(errorCode, '+', errorMessage);
      })
      .finally(() => setLoading(false));


  }
  return (
    <Row style={{height: '100vh' }} align={'middle'} justify={'center'}>
      <Col span={'12'} style={{backgroundColor: '#112954', height: '100vh', backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center', backgroundSize:'contain'}}>

      </Col>
      <Col span={'12'}>
        <LoginForm
          onFinish={async (values) => {
            onSubmit(values);
          }}
          submitter={{
            // Configure the button text
            searchConfig: {
              submitText: "Login",
            },
          }}
        >
          {/*<img src={Logo} style={{ height: "auto", width: 150, marginBottom: 24 }} alt="Pentabilities Logo" />*/}
          {loginType === "account" && (
            <>
              <ProFormText
                name="username"
                fieldProps={{
                  size: "large",
                  prefix: <UserOutlined className={"prefixIcon"} />,
                }}
                placeholder={"Email"}
                rules={[
                  {
                    required: true,
                    message: "Required email",
                  },
                ]}
              />
              <ProFormText.Password
                name="password"
                fieldProps={{
                  size: "large",
                  prefix: <LockOutlined className={"prefixIcon"} />,
                }}
                placeholder={"Password"}
                rules={[
                  {
                    required: true,
                    message: "Required password",
                  },
                ]}
              />
            </>
          )}
        </LoginForm>
      </Col>
    </Row>
  )
}

export default Login;